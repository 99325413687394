import { Card, CardActions, CardContent, CardHeader, CardMedia, Grid, Typography } from '@mui/material';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { Item } from 'features/QuikSpec/Types/QuikSpecTypes';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import React from 'react';
import RadioButton from './QuikspecRadioButton';
import QuantityInputForm from './QuantityInputForm';

interface ExpandedProductCardProps {
  item: Item;
  index: number;
  selectedItem: string;
  changeSelectedItem: (itemName: string) => void;
}

const ExpandedProductCard = ({ item, index, selectedItem, changeSelectedItem }: ExpandedProductCardProps) => {
  const {
    deviceName,
    itemTitle,
    advantages,
    multipleAllowed,
    required,
    maxQuantityAllowedPerItem,
    accessories,
    countWithAccessories,
    imageExtension,
    readOnly
  } = item;
  return (
    <Grid item key={index}>
      <Card sx={styles.card}>
        <CardHeader
          title={
            <Grid sx={styles.cardHeaderWrapper}>
              <Grid>{deviceName}</Grid>
              <Grid sx={styles.cardHeaderSubtitle}>{itemTitle}</Grid>
            </Grid>
          }
          sx={styles.header}
        />
        <CardContent sx={styles.cardContent}>
          <CardMedia
            component="img"
            image={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/${deviceName}.${imageExtension || 'jpg'}`}
            sx={styles.deviceImage}
          />

          <Grid item sx={styles.cardDetailsWrapper}>
            <Grid item xs={12} sx={styles.advantagesWrapper}>
              {advantages &&
                advantages.map((item, index) => (
                  <Grid key={index} sx={styles.advantagesItem}>
                    <FiberManualRecordIcon sx={styles.advItemIcon} />
                    <Typography variant="h6" component="div">
                      {item}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={styles.cardFooter}>
          {/**
           * We assume that if the 'multipleAllowed' value is false, we should always display the radioButton component.
           * If there will be a case when the item has 'multipleAllowed' value is false,
           * but we can select several items in the same category, we just need implement the checkbox component.
           * */}
          {multipleAllowed === false ? (
            <Grid item sx={styles.radioWrapper}>
              <RadioButton
                value={deviceName}
                label={deviceName}
                checked={deviceName === selectedItem}
                onChange={() => changeSelectedItem(deviceName)}
              />
            </Grid>
          ) : (
            <QuantityInputForm
              itemName={deviceName}
              multipleAllowed={multipleAllowed}
              isRequired={required}
              isReadOnly={readOnly}
              maxQuantityPerItem={maxQuantityAllowedPerItem}
              accessories={accessories}
              countWithAccessories={countWithAccessories}
            />
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  card: {
    margin: '20px',
    width: '1200px',
    height: 'auto',
    boxShadow: '3',
    position: 'relative'
  },
  header: {
    textAlign: 'center',
    background: 'linear-gradient(to right, #1e3c72, #2a5298)',
    color: '#fff',
    padding: '4px',
    boxShadow: 'inset 0 -1px 0 0 #e0e0e0',
    fontSize: '1.1rem',
    fontWeight: '200'
  },
  cardHeaderWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '10px'
  },
  cardHeaderSubtitle: {
    marginLeft: '20px',
    fontSize: '16px'
  },
  cardContent: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex'
  },
  categoryDescription: {
    my: 2,
    py: 2
  },
  descriptionContainer: {
    color: '#2c3e50',
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 500
  },
  cardDetailsWrapper: {
    display: 'flex',
    width: '70%',
    flexDirection: 'column'
  },
  advantagesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '35px'
  },
  advantagesItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  advItemIcon: {
    marginRight: '10px',
    fontSize: 'small'
  },
  deviceImage: {
    objectFit: 'contain',
    width: 'auto',
    height: 'auto',
    maxWidth: '300px',
    maxHeight: '300px',
    margin: 'auto',
    display: 'block'
  },
  advancedIcon: {
    marginRight: '10px',
    fontSize: 'small'
  },
  radioWrapper: {
    marginLeft: '10px'
  },
  cardFooter: {
    background: 'rgba(0,0,0,0.03)',
    border: '1px solid rgba(211,211,211,0.6)'
  }
};

export default ExpandedProductCard;
