import { Status } from 'shared/utils/Status';
import { IDevice } from 'store/slices/devicesSlice';
import CONFIG from 'config';

export const firmwareUrl = CONFIG.openApiEndpoint + '/firmware/download/';
export const latestFirmwareUrl = CONFIG.openApiEndpoint + '/firmware/latest';

/**
 *  Calculate Hash value of serial number
] * @param macAddress 
 * @returns hash value of serial number 
 */
export const getSerialNumber = (macAddress: string) => {
  const FNV_PRIME_64 = BigInt('0x100000001b3');
  const FNV_OFFSET_BASIS_64 = BigInt('14695981039346656037');

  let hash = FNV_OFFSET_BASIS_64;

  for (let i = 0; i < macAddress.length; i++) {
    hash ^= BigInt(macAddress.charCodeAt(i));
    hash = (hash * FNV_PRIME_64) % BigInt('0x10000000000000000'); // Ensure 32-bit overflow
  }

  return hash.toString(16); // Return hash as hexadecimal string
};

/**
 * Calculate the credentials based on the last synced date and last updated date
 * @param entity
 * @returns id and password
 */
export const computeCredentials = (entity: {
  lastSyncedOn: Date | null;
  lastUpdatedOn: Date | null;
  basicInfo: { memoId: string; memoPass: string; adminId: string; adminPass: string };
}) => {
  const { lastSyncedOn, lastUpdatedOn, basicInfo } = entity;
  const { memoId, memoPass, adminId, adminPass } = basicInfo;

  const isFirstSync = lastSyncedOn === null;
  const needSync = lastSyncedOn !== null && lastUpdatedOn !== null && lastSyncedOn < lastUpdatedOn;

  // If the entity is newly registered or needs sync, return memo credentials
  if (isFirstSync || needSync) {
    return {
      id: memoId,
      password: memoPass
    };
  } else {
    return {
      id: adminId,
      password: adminPass
    };
  }
};

/**
 * Compute the gateway credentials based on the gateway's properties
 *
 * @param gateway
 * @returns gwId and gwPassword
 */
export const computeGwCredential = (gateway: IDevice) => {
  if (!gateway) {
    return {
      gwId: '',
      gwPassword: ''
    };
  }

  const credentials = computeCredentials(gateway);
  return {
    gwId: credentials.id,
    gwPassword: credentials.password
  };
};

/**
 * Get the device status based on the device's properties
 * @param device
 * @returns return the device status based on the device's properties
 */
export const getDeviceStatus = (device: IDevice) => {
  if (!device.networkSettings?.ipV4Address || device.networkSettings?.ipV4Address === '0.0.0.0') {
    return Status.NeedsAssociation;
  } else if (
    device.unitPublicId === null &&
    device.basicInfo.deviceType !== 6 &&
    device.basicInfo.deviceType !== 17 &&
    device.basicInfo.deviceType !== 18
  ) {
    return Status.NeedsUnit;
  } else if (device.basicInfo?.stationNumber === '') {
    return Status.NeedsStationNumber;
  } else if (
    (device.basicInfo.deviceType === 4 ||
      device.basicInfo.deviceType === 14 ||
      device.basicInfo.deviceType === 15 ||
      device.basicInfo.deviceType === 16) &&
    device.systemInfo?.addressBook?.[0]?.targetDevicePublicId === undefined &&
    device.systemInfo?.addressBook?.[0]?.targetUnitPublicId === undefined
  ) {
    return Status.NeedsAddressBook;
  } else if (
    (device.basicInfo.deviceType === 5 ||
      device.basicInfo.deviceType === 8 ||
      device.basicInfo.deviceType === 9 ||
      device.basicInfo.deviceType === 10 ||
      device.basicInfo.deviceType === 11 ||
      device.basicInfo.deviceType === 12 ||
      device.basicInfo.deviceType === 20) &&
    device?.callSettings?.contactGroupList[1].targetList?.[0]?.targetDevicePublicId === undefined &&
    device?.callSettings?.contactGroupList[1].targetList?.[0]?.targetUnitPublicId === undefined
  ) {
    return Status.NeedsCallList;
  } else if (
    device.lastSyncedOn === null ||
    device.lastUpdatedOn === null ||
    new Date(device.lastSyncedOn) < new Date(device.lastUpdatedOn)
  ) {
    return Status.NeedsSync;
  } else {
    return Status.StandBy;
  }
};
