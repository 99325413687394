import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Container, Typography, Grid, Alert, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentUser, IBranchPermission, IBranchPermissions, IUserPermissions } from 'store/slices/usersSlice';
import useRefetchData from './Hooks/useRefetchData';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { useTranslation } from 'react-i18next';
import MFASettingsDialog from '../../shared/components/MFASettingsDialog';

export const hasNonAssociateAccessInBranch = (branch: any): boolean => {
  const allowedRoles = ['Member', 'Manager', 'Admin'];
  return branch.roleList.some((role: any) => allowedRoles.includes(role.roleName));
};

const convertPermissionsBranchToArray = (branchPermissions: IBranchPermissions): IBranchPermission[] => {
  return Object.values(branchPermissions);
};

const Home = () => {
  useRefetchData();
  const navigate = useNavigate();

  const currentUser = useSelector(getCurrentUser);

  const { t } = useTranslation(); // Use the useTranslation hook for translations
  const permissions: IUserPermissions | undefined = currentUser?.permissions;
  const registeredUser = currentUser ? !permissions || (!permissions.global.roleList && !permissions.branch) : true;
  const hasGlobalRole =
    permissions && permissions.global.roleList !== null && permissions.global.roleList !== undefined;
  const branches: IBranchPermission[] = permissions?.branch ? convertPermissionsBranchToArray(permissions.branch) : [];
  const hasNonAssociateBranchRole = branches.some((branch) => hasNonAssociateAccessInBranch(branch));
  const canAccessSimBilling = !registeredUser && (hasGlobalRole || hasNonAssociateBranchRole);
  const canAccessRemoteManagement = !registeredUser && (hasGlobalRole || hasNonAssociateBranchRole);

  /*State*/
  const [hoveredTile, setHoveredTile] = useState<number | null>(null);
  const [showAccessAlert, setShowAccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isMFASettingsDialogOpen, setIsMFASettingsDialogOpen] = useState(false);

  /*Handlers*/
  const handleMouseEnter = (index: number) => setHoveredTile(index);
  const handleMouseLeave = () => setHoveredTile(null);
  const handleSimBillingClick = () => {
    if (canAccessSimBilling) {
      navigate('/simbilling');
    }

    if (!hasNonAssociateBranchRole) {
      if (registeredUser) {
        setAlertMessage(t('home.tiles.alerts.joinDealerSimBilling'));
      } else {
        setAlertMessage(t('home.tiles.alerts.noBranchAccessSimBilling'));
      }

      setShowAccessAlert(true);
    }
  };
  const handleRemoteManagementClick = () => {
    if (canAccessRemoteManagement) {
      navigate('/remotemanagement');
    }

    if (!canAccessRemoteManagement) {
      if (registeredUser) {
        setAlertMessage(t('home.tiles.alerts.joinDealerRemoteManagement'));
      } else {
        setAlertMessage(t('home.tiles.alerts.noBranchAccessRemoteManagement'));
      }

      setShowAccessAlert(true);
    }
  };
  const handleQuickSpecClick = () => {
    navigate('/quikspec');
  };

  useEffect(() => {
    if (process.env.REACT_APP_STAGE === 'staging') {
      const TIMEOUT_DURATION = 1000;
      const mfaDialogTimeout = setTimeout(() => {
        if (currentUser?.mfaEnabled === null) {
          setIsMFASettingsDialogOpen(true);
        }
      }, TIMEOUT_DURATION);
      return () => clearTimeout(mfaDialogTimeout);
    }
  }, []);

  const tiles = [
    {
      id: 0,
      primaryTitle: t('home.tiles.quikSpec.title'), // Use translation keys for titles
      primaryDescription: t('home.tiles.quikSpec.description'),
      hoverText: t('home.tiles.quikSpec.hoverText'),
      imageSrc: `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/quikspec_logo.jpg`,
      onClick: handleQuickSpecClick
    },
    {
      id: 1,
      primaryTitle: t('home.tiles.simBilling.title'),
      primaryDescription: t('home.tiles.simBilling.description'),
      hoverText: t('home.tiles.simBilling.hoverText'),
      imageSrc: `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/SIM-Billing_Logo.png`,
      onClick: handleSimBillingClick
    },
    {
      id: 2,
      primaryTitle: t('home.tiles.remoteManagement.title'),
      primaryDescription: t('home.tiles.remoteManagement.description'),
      hoverText: t('home.tiles.remoteManagement.hoverText'),
      imageSrc: `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/Remote-Management_Logo.png`,
      onClick: handleRemoteManagementClick
    }
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={styles.logoWrapper} component="img" src="/AiphoneCloud-Logo_Blue.png" alt="AiphoneCloud Logo" />
      <Grid container spacing={4} marginTop={4}>
        {tiles.map((tile) => (
          <Grid item xs={12} md={4} key={tile.id}>
            <Card
              onMouseEnter={() => handleMouseEnter(tile.id)}
              onMouseLeave={handleMouseLeave}
              onClick={tile.onClick}
              sx={styles.mainContainer}
            >
              <CardContent sx={styles.cardContent}>
                <Box
                  component="img"
                  src={tile.imageSrc}
                  alt={`${tile.primaryTitle} Logo`}
                  sx={tile.primaryTitle === 'QuikSpec' ? styles.quikSpecImage : styles.featureImage}
                />
                <Typography variant="body1" sx={styles.textWrapper}>
                  {tile.primaryDescription}
                </Typography>
                {hoveredTile === tile.id && (
                  <Box sx={styles.featureDetails}>
                    <Typography variant="h6" sx={styles.hoverDisplay}>
                      {tile.hoverText}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Snackbar
        open={showAccessAlert}
        autoHideDuration={5000}
        onClose={() => setShowAccessAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="info">{alertMessage}</Alert>
      </Snackbar>
      {
        // The mfaEnabled can be null only for newly registered users. In this case, we need to show the MFA settings dialog.
        // After that, mfaEnabled will be set to either true or false.
        currentUser?.mfaEnabled === null && (
          <MFASettingsDialog
            dialogIsOpen={isMFASettingsDialogOpen}
            setDialogIsOpen={setIsMFASettingsDialogOpen}
            setBaseCondition={true}
          />
        )
      }
    </Container>
  );
};

const styles = {
  logoWrapper: {
    padding: 3,
    display: 'block',
    margin: '0 auto',
    width: '100%',
    maxWidth: '600px'
  },
  featureImage: {
    width: '40%',
    margin: '0 auto',
    objectFit: 'contain',
    maxHeight: '100px',
    minWidth: '100px'
  },
  quikSpecImage: {
    width: '60%',
    margin: '0 auto',
    objectFit: 'contain',
    maxHeight: '120px',
    marginBottom: '20px',
    minWidth: '130px'
  },
  textWrapper: {
    marginTop: '16px',
    flexGrow: 1
  },
  featureDetails: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 2,
    borderRadius: '4px'
  },
  mainContainer: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '220px'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '200px',
    maxHeight: '220px'
  },
  hoverDisplay: {
    textAlign: 'center'
  }
};

export default Home;
