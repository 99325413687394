import React from 'react';
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColumnHeaderParams,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
  MuiEvent
} from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { Popover, Typography } from '@mui/material';

/**
 * Represents the type of time selector, which can be in milliseconds ('ms') or seconds ('s').
 *
 * - 'ms': Represents the time in milliseconds.
 * - 's': Represents the time in seconds.
 */
export type TimeSelectorType = 'ms' | 's';

/**
 * Represents a relay identifier.
 *
 * The RelayNumber type can be one of the following strings:
 * - 'relay_1'
 * - 'relay_2'
 * - 'relay_3'
 * - 'relay_4'
 * - 'relay_5'
 * - 'relay_6'
 * - 'relay_7'
 * - 'relay_8'
 * - 'relay_9'
 * - 'relay_10'
 * - 'relay_11'
 * - 'relay_12'
 * - 'relay_13'
 * - 'relay_14'
 * - 'relay_15'
 * - 'relay_16'
 * - 'relay_17'
 * - 'relay_18'
 * - 'relay_19'
 * - 'relay_20'
 *
 * This helps us to avoid the accessing undefined property error when accessing the relay properties in the rowData object.
 */
export type RelayNumber =
  | 'relay_1'
  | 'relay_2'
  | 'relay_3'
  | 'relay_4'
  | 'relay_5'
  | 'relay_6'
  | 'relay_7'
  | 'relay_8'
  | 'relay_9'
  | 'relay_10'
  | 'relay_11'
  | 'relay_12'
  | 'relay_13'
  | 'relay_14'
  | 'relay_15'
  | 'relay_16'
  | 'relay_17'
  | 'relay_18'
  | 'relay_19'
  | 'relay_20';

/**
 * Handles changes to the cell modes model in a grid.
 *
 * @param {GridCellModesModel} newModel - The new model representing the cell modes.
 * @param {function} setCellModesModel - The callback function to update the cell modes model.
 * @returns {void}
 */
export const handleCellModesModelChange = (
  newModel: GridCellModesModel,
  setCellModesModel: (newModel: GridCellModesModel) => void
): void => {
  setCellModesModel(newModel);
};

/**
 * Handles cell click event in a grid.
 *
 * @param {GridCellParams} params - Parameters of the clicked cell.
 * @param {React.MouseEvent} event - The mouse event triggered by clicking the cell.
 * @param {React.Dispatch<React.SetStateAction<GridCellModesModel>>} setCellModesModel - Function to update the cell modes model state.
 *
 * The function performs the following tasks:
 * 1. Ignores the click if it's detected to be within a portal.
 * 2. Prevents any action if the clicked cell's field is 'Stations'.
 * 3. Reverts other cells to view mode, then sets the clicked cell to edit mode.
 */
export const handleCellClick = (
  params: GridCellParams,
  event: React.MouseEvent,
  setCellModesModel: React.Dispatch<React.SetStateAction<GridCellModesModel>>
) => {
  // Ignore portal
  if ((event.target as any).nodeType === 1 && !event.currentTarget.contains(event.target as Element)) {
    return;
  }

  // Prevent click event on Stations cell
  if (params.field === 'Stations') {
    // Stop the selection of the cell
    event.stopPropagation();

    return;
  }

  setCellModesModel((prevModel) => {
    return {
      // Revert the mode of the other cells from other rows
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce(
            (acc2, field) => ({
              ...acc2,
              [field]: { mode: GridCellModes.View }
            }),
            {}
          )
        }),
        {}
      ),
      [params.id]: {
        // Revert the mode of other cells in the same row
        ...Object.keys(prevModel[params.id] || {}).reduce(
          (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
          {}
        ),
        [params.field]: { mode: GridCellModes.Edit }
      }
    };
  });
};

/**
 * Handles the click event for a row in a grid component.
 *
 * @param {GridRowParams} params - The parameters for the grid row that was clicked.
 * @param {MuiEvent<MouseEvent>} event - The mouse event triggered by the row click.
 *
 * Stops the propagation of the event if the row is disabled.
 */
export const handleRowClick = (params: GridRowParams, event: MuiEvent<MouseEvent>) => {
  if (params.row.disabled) {
    event.stopPropagation();
  }
};

// Singular row data
export const rowData = [
  {
    id: 'unit-1', // Will be the public id
    stationName: 'Station 1',
    stationNumber: 1,
    unitName: 'Unit 1',
    unitNumber: 1,
    relay_1: {
      enabled: false,
      delay: 0
    },
    relay_2: {
      enabled: false,
      delay: 0
    },
    relay_3: {
      enabled: false,
      delay: 0
    },
    relay_4: {
      enabled: false,
      delay: 0
    },
    relay_5: {
      enabled: false,
      delay: 0
    },
    relay_6: {
      enabled: false,
      delay: 0
    },
    relay_7: {
      enabled: false,
      delay: 0
    },
    relay_8: {
      enabled: false,
      delay: 0
    },
    relay_9: {
      enabled: false,
      delay: 0
    },
    relay_10: {
      enabled: false,
      delay: 0
    },
    relay_11: {
      enabled: false,
      delay: 0
    },
    relay_12: {
      enabled: false,
      delay: 0
    },
    relay_13: {
      enabled: false,
      delay: 0
    },
    relay_14: {
      enabled: false,
      delay: 0
    },
    relay_15: {
      enabled: false,
      delay: 0
    },
    relay_16: {
      enabled: false,
      delay: 0
    },
    relay_17: {
      enabled: false,
      delay: 0
    },
    relay_18: {
      enabled: false,
      delay: 0
    },
    relay_19: {
      enabled: false,
      delay: 0
    },
    relay_20: {
      enabled: false,
      delay: 0
    }
  }
];

/**
 * A hook that provides various handlers for managing data grid operations
 * and interactions. The hook uses the provided Grid API reference to update
 * and manipulate rows in the grid.
 *
 * @param {React.MutableRefObject<GridApiCommunity>} apiRef - A ref object representing the Grid API.
 * @returns {Object} An object containing the following handlers:
 * - `submitHandler`: A function to submit and log the current row models.
 * - `handleUpdateRelayDelay`: A function to update the `delay` property of cells in the specified column.
 * - `handleCheckCell`: A function to update the `enabled` property of cells in the specified column.
 * - `handleSwitchCell`: A function to update the value of cells in the specified column.
 */
export const useDataGridHandlers = (apiRef: React.MutableRefObject<GridApiCommunity>) => {
  const submitHandler = React.useCallback(() => {
    console.log([...apiRef.current.getRowModels().values()]);
  }, [apiRef]);

  const handleUpdateRelayDelay = React.useCallback(
    (params: GridColumnHeaderParams, newDelay: number) => {
      apiRef.current.updateRows(
        [...apiRef.current.getRowModels().values()].map((row: any) => ({
          ...row,
          [params.field]: {
            ...row[params.field],
            delay: newDelay
          }
        }))
      );
    },
    [apiRef]
  );

  const handleCheckCell = React.useCallback(
    (params: GridRenderCellParams, newCheckedValue: boolean) => {
      apiRef.current.updateRows(
        [...apiRef.current.getRowModels().values()].map((row: any) => ({
          ...row,
          [params.field]: {
            ...row[params.field],
            enabled: newCheckedValue
          }
        }))
      );
    },
    [apiRef]
  );

  const handleSwitchCell = React.useCallback(
    (params: GridRenderCellParams, newSwitchValue: boolean) => {
      // Updates the liftControlEnabled value for all rows
      apiRef.current.updateRows(
        [...apiRef.current.getRowModels().values()].map((row: any) => ({
          ...row,
          [params.field]: newSwitchValue
        }))
      );
    },
    [apiRef]
  );

  return {
    submitHandler,
    handleUpdateRelayDelay,
    handleCheckCell,
    handleSwitchCell
  };
};

/**
 * A hook that manages the state and behavior of a popover for a grid row.
 *
 * @param {React.MutableRefObject<GridApiCommunity>} apiRef - A reference to the grid API.
 * @returns {Object} An object containing methods to handle popover state and rendering.
 * @property {function} handlePopoverClose - Closes the popover and resets its state.
 * @property {function} handlePopoverOpen - Opens the popover and sets its state based on the grid row data.
 * @property {function} renderPopover - Renders the popover component.
 */
export const useGridRowPopover = (apiRef: React.MutableRefObject<GridApiCommunity>) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [stationName, setStationName] = React.useState<undefined | string>();
  const isLeftMostColumnInView = React.useRef<boolean>(true);
  const popoverOpen = Boolean(anchorEl);

  /**
   * Function to handle closing of a popover in a user interface.
   *
   * This function performs the following actions:
   * - Sets the anchor element to null.
   * - Resets the station name to undefined.
   *
   * The primary purpose is to close any open popover component and reset the relevant state variables when invoked.
   *
   * @function
   */
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setStationName(undefined);
  };

  /**
   * Handle the opening of a popover based on a mouse event triggered by a user interaction.
   *
   * The popover will only be displayed if the left most column of a table is not currently in view.
   * When triggered, it sets the current station name and anchors the popover to the event's parent element.
   *
   * @param {React.MouseEvent<HTMLElement>} event - The mouse event that triggers the popover.
   */
  const handlePopoverOpen = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const id = event.currentTarget.parentElement?.dataset.id;
      const row = rowData.find((r) => r.id === id);
      if (!id || !row) return;

      // If the left most column is not in view, then we can show the Popover
      if (!isLeftMostColumnInView.current) {
        setStationName(row.stationName);
        setAnchorEl(event.currentTarget.parentElement);
      }
    },
    [isLeftMostColumnInView.current]
  );

  /**
   * Event listener that reacts to changes in the scroll position of a grid.
   * This listener updates `isLeftMostColumnInView` based on whether the
   * leftmost column is in view and manages the visibility of a popover by
   * updating `anchorEl` and `setStationName` state.
   *
   * @type {GridEventListener<'scrollPositionChange'>}
   * @param {Object} params - The parameters containing render context.
   * @callback
   */
  const onScrollPositionChange: GridEventListener<'scrollPositionChange'> = React.useCallback(
    (params) => {
      isLeftMostColumnInView.current = !!(
        params &&
        params.renderContext &&
        params.renderContext.firstColumnIndex !== undefined &&
        params.renderContext.firstColumnIndex <= 0
      );

      if (
        params &&
        params.renderContext &&
        params.renderContext.firstColumnIndex !== undefined &&
        params.renderContext.firstColumnIndex <= 0 &&
        anchorEl !== null
      ) {
        // If leftmost column is in view, then get rid of the popover completely on scrolling
        setAnchorEl(null);
        setStationName(undefined);
      }
    },
    [isLeftMostColumnInView]
  );

  /**
   * Renders a popover component.
   *
   * This function returns a Popover component that displays some text associated
   * with a station name. The popover is controlled by the `popoverOpen` state and
   * anchored to an element specified by `anchorEl`. The popover will close when
   * the `handlePopoverClose` function is called. The appearance and positioning
   * of the popover are controlled through the `sx`, `anchorOrigin`, and
   * `transformOrigin` properties.
   *
   * @returns {JSX.Element} A Popover component containing a Typography element with the station name.
   */
  const renderPopover = () => {
    return (
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{
          pointerEvents: 'none'
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      >
        <Typography sx={{ p: 0.5, borderRadius: '2px', color: '#003366' }}>{stationName}</Typography>
      </Popover>
    );
  };

  React.useEffect(() => {
    if (apiRef.current) {
      apiRef.current.subscribeEvent('scrollPositionChange', onScrollPositionChange);
    }
  }, [apiRef, onScrollPositionChange]);

  return {
    handlePopoverClose,
    handlePopoverOpen,
    renderPopover
  };
};
