import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { MarketType } from '../../../RemoteManagement/Types';
import { ErrorMessage, Field, useField } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import React from 'react';

import { useTranslation } from 'react-i18next';

interface GeneralInfoProps {
  editing: boolean;
}

const GeneralInfo = ({ editing }: GeneralInfoProps) => {
  const { t } = useTranslation();
  const generalSystemInfoTitle = t('Title_General_System_Information');
  const jobName = t('Job_Name');
  const marketType = t('Market_Type');
  const nameBranch = t('Branch_Name');

  const getBranches = useSelector((state: RootState) => state.users.currentUser?.permissions?.branch) || {};
  const branchList = Object.keys(getBranches).map((branchId) => {
    return {
      branchId,
      branchName: getBranches[branchId]?.branchName
    };
  });

  const userBelongsToBranch = branchList.length > 0;
  //TODO: retrieve branch names from backend
  const [, marketTypeMeta] = useField('marketType');
  const [, quikspecNameMeta] = useField('quikspecName');
  const [branchPublicId, branchPublicIdMeta] = useField('branchPublicId');

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography align="center" variant="h5" component="h5" sx={styles.cardTitle}>
            {generalSystemInfoTitle}
          </Typography>
          <Divider />
          <Grid container spacing={1}>
            <Grid item xs={2} sm={2} md={1} sx={styles.textBoxDescription}>
              <Typography variant="h6" component="h6">
                {jobName}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={11}>
              <Field
                name="quikspecName"
                as={TextField}
                type="text"
                label={t('Enter_job_name')}
                sx={styles.cardContent}
                disabled={editing}
                fullWidth
                error={Boolean(quikspecNameMeta.touched && quikspecNameMeta.error)}
              ></Field>
              <Box sx={{ ...styles.errorMessage, ml: 1 }}>
                <ErrorMessage name="quikspecName" />
              </Box>
            </Grid>
            <Grid item xs={2} sm={2} md={1} sx={styles.textBoxDescription}>
              <Typography variant="h6" component="h6">
                {marketType}
              </Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={11}>
              <FormControl fullWidth>
                <Field
                  as={Select}
                  id="marketType"
                  name="marketType"
                  variant="outlined"
                  sx={styles.cardContent}
                  error={Boolean(marketTypeMeta.touched && marketTypeMeta.error)}
                >
                  {MarketType.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.value}
                    </MenuItem>
                  ))}
                </Field>
                <Box sx={styles.errorMessage}>
                  <ErrorMessage name="marketType" />
                </Box>
              </FormControl>
            </Grid>
            {userBelongsToBranch && (
              <>
                <Grid item xs={2} sm={2} md={1} sx={styles.textBoxDescription}>
                  <Typography variant="h6" component="h6">
                    {nameBranch}
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={10} md={11}>
                  <FormControl fullWidth>
                    <Field
                      as={Select}
                      id="branchPublicId"
                      name="branchPublicId"
                      variant="outlined"
                      disabled={editing}
                      sx={styles.cardContent}
                      helperText={branchPublicIdMeta.error}
                      error={Boolean(branchPublicIdMeta.touched && branchPublicIdMeta.error)}
                      onChange={branchPublicId.onChange}
                      onBlur={(e: React.FocusEvent<any>) => branchPublicId.onBlur(e)}
                      value={branchPublicId.value}
                    >
                      {branchList.map((type) => (
                        <MenuItem key={type.branchId} value={type.branchId}>
                          {type.branchName}
                        </MenuItem>
                      ))}
                    </Field>
                    <Box sx={styles.errorMessage}>
                      <ErrorMessage name="branchPublicId" />
                    </Box>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

const styles = {
  cardTitle: {
    p: 0
  },
  cardContent: {
    m: 1,
    minWidth: 200
  },
  errorMessage: {
    color: '#d32f2f',
    fontSize: '.75rem'
  },
  textBoxDescription: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export default GeneralInfo;
