import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarContainer
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useGetCompaniesQuery } from 'services/aiphoneCloud';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import AddCompanyDialog from '../UiParts/AddCompanyDialog';
import DeleteCompanyDialog from '../UiParts/DeleteCompanyDialog';
import SettingsIcon from '@mui/icons-material/Settings';
import EditCompanyDialog, { Company } from '../UiParts/EditCompanyDialog';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useTranslation } from 'react-i18next';

export type CompanyResponse = {
  companyList: {
    [key: string]: Company;
  };
  perPage: number;
  pageNumber: number;
  totalPages: number;
  totalCompanies: number;
};

export type CompanyRecord = {
  publicId: string;
  name: string;
  stateId: number;
  postalCode: string;
  companyTypeId: number;
  dealerCompanyPublicId: string;
  createdOn: string;
  lastUpdatedOn: string;
  createdBy: string;
  lastUpdatedBy: string;
};

const CompaniesDataGrid = () => {
  const defaultPageSize = 50;
  const { t } = useTranslation(); // Initialize the translation hook

  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [selectedCompany, setSelectedCompany] = React.useState<Company>({
    id: '',
    name: '',
    stateId: 0,
    postalCode: '',
    companyTypeId: 0,
    dealerCompanyPublicId: ''
  });
  const [isCreateCompanyDialogOpen, setisCreateCompanyDialogOpen] = React.useState(false);
  const [isDeleteCompanyDialogOpen, setisDeleteCompanyDialogOpen] = React.useState(false);
  const [isEditCompanyDialogOpen, setisEditCompanyDialogOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isError, setIsError] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [companiesList, setCompaniesList] = React.useState<{ [key: string]: CompanyRecord }>({});
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [firstPageLoaded, setFirstPageLoaded] = useState(false);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchData = () => {
    return useGetCompaniesQuery({
      qty: pageSize.toString(),
      page: currentPage.toString()
    });
  };

  /* Fetch companies */
  const { data, error, refetch } = fetchData();
  const { isAllowedTo } = usePermission();
  const canCreateCompany = isAllowedTo('company:create', null, PermissionsContextType.GLOBAL);
  const canEditCompany = isAllowedTo('company:edit', null, PermissionsContextType.GLOBAL);
  const canDeleteCompany = isAllowedTo('company:delete', null, PermissionsContextType.GLOBAL);
  const canViewCompany = isAllowedTo('company:view', null, PermissionsContextType.GLOBAL);

  useEffect(() => {
    if ((data || error) && isSuccess) {
      refetch();
    }
  }, [page, pageSize, isSuccess]);

  useEffect(() => {
    if (data?.companyList) {
      setCompaniesList({ ...companiesList, ...data.companyList });
      if (page === 0) {
        setFirstPageLoaded(true);
      }
    }
    if (data) {
      const nextPageAvailable: boolean = data.pageNumber < data.totalPages;
      if (nextPageAvailable) {
        setCurrentPage(currentPage + 1);
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const errorData = JSON.parse((error as { data: string }).data);
      const errorCode = errorData.errorCode;
      if (errorCode === 'A0001') {
        setErrorMessage(t('Error_Unauthoirzed_Fetch_Companies'));
      } else {
        setErrorMessage(t('Error_Failed_Fetch_Companies'));
      }
      setIsError(true);
    }
  }, [error]);

  useEffect(() => {
    setRows(computeRows(companiesList));
  }, [companiesList]);

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  const computeRows = (companies: { [key: string]: CompanyRecord }): GridRowsProp => {
    if (Object.keys(companies).length === 0) {
      return [];
    }
    return Object.entries(companies).map(([key, company]) => ({
      id: key,
      name: company.name,
      state: enumList.state[company.stateId].value,
      stateId: company.stateId,
      postalCode: company.postalCode,
      companyTypeId: company.companyTypeId,
      dealerCompanyPublicId: company.dealerCompanyPublicId
    }));
  };

  const computeColumns = (): GridColDef[] => {
    const columns: GridColDef[] = [
      { field: 'name', headerName: t('Company_Name'), width: 200, flex: 1 },
      { field: 'state', headerName: t('State'), width: 90, flex: 1 },
      { field: 'postalCode', headerName: t('Zip_Code'), width: 90, flex: 1 }
    ];

    if (canEditCompany) {
      columns.push({
        field: 'actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SettingsIcon />}
            label={t('Edit')}
            onClick={() => {
              setisEditCompanyDialogOpen(true);
              setSelectedCompany(params.row);
            }}
          />
        ]
      });
    }

    return columns;
  };

  const CompaniesToolBar = () => {
    return (
      <GridToolbarContainer>
        <Box sx={styles.w100}>
          {canCreateCompany && (
            <Button onClick={() => setisCreateCompanyDialogOpen(true)} disabled={!firstPageLoaded} startIcon={<Add />}>
              {t('Add_Company')}
            </Button>
          )}
          {canDeleteCompany && (
            <Button
              onClick={() => setisDeleteCompanyDialogOpen(true)}
              disabled={!firstPageLoaded || selectionModel.length !== 1}
              startIcon={<DeleteForeverIcon />}
            >
              {t('Delete_Company')}
            </Button>
          )}
        </Box>
      </GridToolbarContainer>
    );
  };

  //const rows: GridRowsProp = !isFetching && !fetchingEnums ? computeRows() : [];
  const columns: GridColDef[] = computeColumns();

  return (
    <>
      {canViewCompany && (
        <DataGrid
          rows={rows}
          columns={columns}
          loading={!firstPageLoaded}
          checkboxSelection
          disableRowSelectionOnClick
          pageSizeOptions={[25, 50, 100]}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          slots={{ toolbar: CompaniesToolBar }}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          pagination
          autoHeight
        />
      )}
      <AddCompanyDialog
        isOpen={isCreateCompanyDialogOpen}
        setIsOpen={setisCreateCompanyDialogOpen}
        setIsError={setIsError}
        setErrorMessage={setErrorMessage}
        setIsSuccess={setIsSuccess}
        setSuccessMessage={setSuccessMessage}
      />
      <EditCompanyDialog
        isOpen={isEditCompanyDialogOpen}
        setIsOpen={setisEditCompanyDialogOpen}
        company={selectedCompany}
        setIsError={setIsError}
        setErrorMessage={setErrorMessage}
        setIsSuccess={setIsSuccess}
        setSuccessMessage={setSuccessMessage}
      />
      <DeleteCompanyDialog
        isOpen={isDeleteCompanyDialogOpen}
        setIsOpen={setisDeleteCompanyDialogOpen}
        selectionModel={selectionModel}
        setIsError={setIsError}
        setErrorMessage={setErrorMessage}
        setIsSuccess={setIsSuccess}
        setSuccessMessage={setSuccessMessage}
      />
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
      />
    </>
  );
};

const styles = {
  w100: {
    width: '100%'
  }
};

export default CompaniesDataGrid;
