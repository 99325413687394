import React, { useEffect, useState } from 'react';
import { Box, Card, CardHeader, CardContent, CardMedia, Checkbox, Grid, Tab, Tabs, Typography } from '@mui/material';
import theme from 'data/theme';
import ProductCard from 'features/QuikSpec/Components/UiParts/ProductCard';
import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';
import ExpandedProductCard from './ExpandedProductCard';
import useQuantityChange from '../../Hooks/useQuantityChange';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import ProductPopover from 'features/QuikSpec/Components/UiParts/ProductPopover';
import RadioButton from './QuikspecRadioButton';
import QuantityInputForm from 'features/QuikSpec/Components/UiParts/QuantityInputForm';

import { useTranslation } from 'react-i18next';
import { useQuikSpecViewDetail } from 'features/QuikSpec/Hooks/quikSpecDevices';

interface Props {
  categories: QuikspecCategory[];
}

const QuikspecCategoryGtEntranceGuard = ({ categories }: Props) => {
  const defaultItemsPerPage = 4;
  const { t } = useTranslation();
  const { description } = useQuikSpecViewDetail();
  const [page, setPage] = React.useState(1);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedRadioItem, setSelectedRadioItem] = useState<string>('');
  const [callingMethod, setCallingMethod] = useState<string>('');
  const { handleQuantityChange, directChangeQuantity, directGetQuantity } = useQuantityChange(
    selectedRadioItem,
    false,
    1
  );

  /**
   * 1)If any item within a category has its multipleAllowed set to false, we will handle it using RadioButton.
   * We'll assume there are multiple items with multipleAllowed set to false.
   * In such cases, we'll take the first item as the default value.
   *
   * 2) The handleQuantityChange function should be executed within a useEffect hook because we need to wait until selectedRadioItem is updated.
   * This ensures that changes are handled correctly after the state has been updated.
   * */
  useEffect(() => {
    if (!selectedRadioItem) {
      categories[selectedTab].items.forEach((item) => {
        /**
         * If 'selectedRadioItem' is not empty, it means we have already found the default value.
         * Therefore, we just need to skip the loop.
         * */
        if (!selectedRadioItem && item?.multipleAllowed === false) {
          return setSelectedRadioItem(item.deviceName);
        }
      });
    }
    handleQuantityChange('1');
  }, [selectedRadioItem]);

  /**
   * When the radio button component updates,
   * it implies that the previous value will always be false ('0'),
   * and the selected value will always be true ('1').
   * In this function, we set the previous value to false.
   * The current value is set in the useEffect component after the state has been updated.
   * */
  const handleRadioBtnChange = (itemName: string) => {
    selectedRadioItem && handleQuantityChange('0');
    setSelectedRadioItem(itemName);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setPage(1);
    setSelectedTab(newValue);
  };

  // Control for Keypad or Call Button
  useEffect(() => {
    if (!callingMethod) {
      if (isKeypad('video')) {
        setCallingMethod('Keypad');
        directChangeQuantity('MODULE_VIDEO_ENTRANCE_KEYPAD', '1');
        directChangeQuantity('MODULE_AUDIO_ENTRANCE_KEYPAD', '1');
      } else {
        setCallingMethod('Button');
        directChangeQuantity('MODULE_VIDEO_ENTRANCE_KEYPAD', '0');
        directChangeQuantity('MODULE_AUDIO_ENTRANCE_KEYPAD', '0');
      }
    } else {
      if (callingMethod === 'Keypad') {
        directChangeQuantity('MODULE_VIDEO_ENTRANCE_KEYPAD', '1');
        directChangeQuantity('MODULE_AUDIO_ENTRANCE_KEYPAD', '1');
      } else {
        directChangeQuantity('MODULE_VIDEO_ENTRANCE_KEYPAD', '0');
        directChangeQuantity('MODULE_AUDIO_ENTRANCE_KEYPAD', '0');
      }
    }
  }, [callingMethod]);
  const isKeypad = (moduleType: string) => {
    if (moduleType === 'video') {
      if (directGetQuantity('MODULE_VIDEO_ENTRANCE_KEYPAD') > 0) {
        return true;
      } else {
        return false;
      }
    } else if (moduleType === 'audio') {
      if (directGetQuantity('MODULE_AUDIO_ENTRANCE_KEYPAD') > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  // Control for NFC option
  const [moduleNFCCheckBox, setModuleNFCCheckBox] = useState<boolean>(false);
  const handleModuleNFCCheckBoxChange = () => {
    if (moduleNFCCheckBox) {
      setModuleNFCCheckBox(false);
      directChangeQuantity('MODULE_VIDEO_ENTRANCE_NFC', '0');
      directChangeQuantity('MODULE_AUDIO_ENTRANCE_NFC', '0');
    } else {
      setModuleNFCCheckBox(true);
      directChangeQuantity('MODULE_VIDEO_ENTRANCE_NFC', '1');
      directChangeQuantity('MODULE_AUDIO_ENTRANCE_NFC', '1');
    }
  };
  useEffect(() => {
    if (!moduleNFCCheckBox) {
      if (isNFC('video')) {
        return setModuleNFCCheckBox(true);
      }
    }
  }, [setModuleNFCCheckBox]);
  const isNFC = (moduleType: string) => {
    if (moduleType === 'video') {
      if (directGetQuantity('MODULE_VIDEO_ENTRANCE_NFC') > 0) {
        return true;
      } else {
        return false;
      }
    } else if (moduleType === 'audio') {
      if (directGetQuantity('MODULE_AUDIO_ENTRANCE_NFC') > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  // Control for Rainhood
  const [rainhoodCheckBox, setRainhoodCheckBox] = useState<boolean>(false);
  const handleRainhoodCheckBoxChange = () => {
    if (rainhoodCheckBox) {
      setRainhoodCheckBox(false);
      directChangeQuantity('MODULE_VIDEO_ENTRANCE_RAINHOOD', '0');
      directChangeQuantity('MODULE_AUDIO_ENTRANCE_RAINHOOD', '0');
    } else {
      setRainhoodCheckBox(true);
      directChangeQuantity('MODULE_VIDEO_ENTRANCE_RAINHOOD', '1');
      directChangeQuantity('MODULE_AUDIO_ENTRANCE_RAINHOOD', '1');
    }
  };
  useEffect(() => {
    if (!rainhoodCheckBox) {
      if (isRainhood('video')) {
        return setRainhoodCheckBox(true);
      }
    }
  }, [setRainhoodCheckBox]);
  const isRainhood = (moduleType: string) => {
    if (moduleType === 'video') {
      if (directGetQuantity('MODULE_VIDEO_ENTRANCE_RAINHOOD') > 0) {
        return true;
      } else {
        return false;
      }
    } else if (moduleType === 'audio') {
      if (directGetQuantity('MODULE_AUDIO_ENTRANCE_RAINHOOD') > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  // Display proper example image for entrance panel
  const moduleEntranceImage = (moduleType: string) => {
    if (moduleType === 'video') {
      if (moduleNFCCheckBox) {
        if (callingMethod === 'Keypad') {
          return `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/GT-Entrance-Video-Keypad-NFC.jpg`;
        } else {
          return `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/GT-Entrance-Video-Button-NFC.jpg`;
        }
      } else {
        if (callingMethod === 'Keypad') {
          return `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/GT-Entrance-Video-Keypad-NoNFC.jpg`;
        } else {
          return `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/GT-Entrance-Video-Button-NoNFC.jpg`;
        }
      }
    } else {
      if (moduleNFCCheckBox) {
        if (callingMethod === 'Keypad') {
          return `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/GT-Entrance-Audio-Keypad-NFC.jpg`;
        } else {
          return `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/GT-Entrance-Audio-Button-NFC.jpg`;
        }
      } else {
        if (callingMethod === 'Keypad') {
          return `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/GT-Entrance-Audio-Keypad-NoNFC.jpg`;
        } else {
          return `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/GT-Entrance-Audio-Button-NoNFC.jpg`;
        }
      }
    }
  };

  const displayController = (id: number) => {
    switch (id) {
      // 3-in-1 Entrance Panel
      case 1:
        return (
          <>
            <Grid item sm={12} md={3} alignItems="center">
              <Card sx={styles.cardWithDetail}>
                <CardHeader
                  title="GT-DMB-N"
                  sx={styles.header}
                  titleTypographyProps={{ fontSize: '1.3rem', fontWeight: '500' }}
                />
                <CardContent sx={styles.cardContent}>
                  <CardMedia
                    component="img"
                    image={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/GT-DMB-N.jpg`}
                    sx={styles.deviceImage}
                  />
                  <ProductPopover description={description.item['GTDMBN']} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={9} sx={styles.form}>
              <Grid container>
                <Grid item sx={styles.questions}>
                  {t('How_many_audio_video')}
                </Grid>
                <Grid item md={12} lg={5}>
                  <QuantityInputForm
                    itemName={'GT-DMB-N'}
                    multipleAllowed={false}
                    maxQuantityPerItem={16}
                    accessories={categories[selectedTab].items[0].accessories}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        );
        break;
      // Customizable Audio Video Entrance Panel
      case 2:
        return (
          <>
            <Grid item sm={12} md={4} alignItems="center">
              <Card sx={styles.cardWithoutDetail}>
                <CardHeader
                  title={t('Entrance_Visual_Example')}
                  sx={styles.header}
                  titleTypographyProps={{ fontSize: '1.3rem', fontWeight: '500' }}
                />
                <CardContent sx={styles.cardContent}>
                  <CardMedia component="img" image={moduleEntranceImage('video')} sx={styles.deviceImage} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={8} sx={styles.form}>
              <Grid container>
                <Grid item md={12} lg={7}>
                  {t('How_many_audio_video')}
                </Grid>
                <Grid item md={12} lg={5}>
                  <QuantityInputForm
                    itemName={'NUMBER_OF_MODULE_VIDEO_ENTRANCE'}
                    multipleAllowed={false}
                    maxQuantityPerItem={16}
                    accessories={categories[0].items[1].accessories}
                  />
                </Grid>
              </Grid>
              <Grid container sx={styles.secondQuestion}>
                <Grid item md={12} lg={7} paddingTop={1}>
                  {t('Will_the_entrance')}
                </Grid>
                <Grid item md={12} lg={5}>
                  <RadioButton
                    value={'Keypad'}
                    label={t('Keypad_with_LCD_Screen')}
                    checked={'Keypad' === callingMethod}
                    onChange={() => setCallingMethod('Keypad')}
                  />
                  <RadioButton
                    value={'Button'}
                    label={t('Individual_Call_Buttons')}
                    checked={'Button' === callingMethod}
                    onChange={() => setCallingMethod('Button')}
                  />
                </Grid>
              </Grid>
              {callingMethod === 'Button' && (
                <Grid container sx={styles.secondQuestion}>
                  <Grid item md={12} lg={7}>
                    {t('How_many_tenants')}
                  </Grid>
                  <Grid item md={12} lg={5}>
                    <QuantityInputForm
                      itemName={'NUMBER_OF_TENANT'}
                      multipleAllowed={false}
                      maxQuantityPerItem={28}
                      accessories={undefined}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Checkbox
                  checked={moduleNFCCheckBox}
                  onChange={() => handleModuleNFCCheckBoxChange()}
                  sx={styles.orgCheckBox}
                />
                {t('Embedded_NFC_Readers')}
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  checked={rainhoodCheckBox}
                  onChange={() => handleRainhoodCheckBoxChange()}
                  sx={styles.orgCheckBox}
                />
                {t('Would_you_rainhood')}
              </Grid>
            </Grid>
          </>
        );
        break;
      // Customizable Audio Only Entrance Panel
      case 3:
        return (
          <>
            <Grid item sm={12} md={4} alignItems="center">
              <Card sx={styles.cardWithoutDetail}>
                <CardHeader
                  title={t('Entrance_Visual_Example')}
                  sx={styles.header}
                  titleTypographyProps={{ fontSize: '1.3rem', fontWeight: '500' }}
                />
                <CardContent sx={styles.cardContent}>
                  <CardMedia component="img" image={moduleEntranceImage('audio')} sx={styles.deviceImage} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={8} sx={styles.form}>
              <Grid container>
                <Grid item md={12} lg={7}>
                  {t('How_many_audio_only')}
                </Grid>
                <Grid item md={12} lg={5}>
                  <QuantityInputForm
                    itemName={'NUMBER_OF_MODULE_AUDIO_ENTRANCE'}
                    multipleAllowed={false}
                    maxQuantityPerItem={16}
                    accessories={categories[0].items[2].accessories}
                  />
                </Grid>
              </Grid>
              <Grid container sx={styles.secondQuestion}>
                <Grid item md={12} lg={7} paddingTop={1}>
                  {t('Will_the_entrance')}
                </Grid>
                <Grid item md={12} lg={5}>
                  <RadioButton
                    value={'Keypad'}
                    label={'Keypad with LCD Screen'}
                    checked={'Keypad' === callingMethod}
                    onChange={() => setCallingMethod('Keypad')}
                  />
                  <RadioButton
                    value={'Button'}
                    label={'Individual Call Buttons'}
                    checked={'Button' === callingMethod}
                    onChange={() => setCallingMethod('Button')}
                  />
                </Grid>
              </Grid>
              {callingMethod === 'Button' && (
                <Grid container sx={styles.secondQuestion}>
                  <Grid item md={12} lg={7}>
                    {t('How_many_tenants')}
                  </Grid>
                  <Grid item md={12} lg={5}>
                    <QuantityInputForm
                      itemName={'NUMBER_OF_TENANT'}
                      multipleAllowed={false}
                      maxQuantityPerItem={28}
                      accessories={undefined}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Checkbox
                  checked={moduleNFCCheckBox}
                  onChange={() => handleModuleNFCCheckBoxChange()}
                  sx={styles.orgCheckBox}
                />
                {t('Embedded_NFC')}
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  checked={rainhoodCheckBox}
                  onChange={() => handleRainhoodCheckBoxChange()}
                  sx={styles.orgCheckBox}
                />
                {t('Would_you_rainhood')}
              </Grid>
            </Grid>
          </>
        );
        break;
      // Guard Station
      case 4:
        return categories[selectedTab].items
          .slice(
            (page - 1) * (categories[selectedTab].itemsPerPage || defaultItemsPerPage),
            page * (categories[selectedTab].itemsPerPage || defaultItemsPerPage)
          )
          .map((item, index) =>
            item?.multipleAllowed === false ? (
              <ExpandedProductCard
                item={item}
                key={index}
                index={index}
                selectedItem={selectedRadioItem}
                changeSelectedItem={handleRadioBtnChange}
              />
            ) : (
              <ProductCard item={item} key={index} index={index} />
            )
          );
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={styles.mainStationsBox}>
      <Grid container justifyContent="center" flexDirection={'column'} sx={styles.mainStationsTabGrid}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {categories.map((product) => (
            <Tab key={product.id} label={product.title} sx={styles.mainStationsTab} />
          ))}
        </Tabs>

        <Grid container justifyContent="center">
          <Grid item xs={12} sx={styles.categoryDescription}>
            <Typography variant="h6" component={'h6'} sx={styles.descriptionContainer}>
              {categories[selectedTab].description}
            </Typography>
          </Grid>
          {displayController(categories[selectedTab].id)}
        </Grid>
      </Grid>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainStationsBox: {
    my: 4,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  mainStationsTabGrid: {
    my: 2,
    width: '95%',
    mx: 'auto'
  },
  mainStationsTab: {
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: '0',
    '&:first-of-type': {
      borderTopLeftRadius: '7px',
      borderBottomLeftRadius: '7px'
    },
    '&:last-of-type': {
      borderTopRightRadius: '7px',
      borderBottomRightRadius: '7px'
    },
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#f0f0f0',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#d0d0d0',
      opacity: 1
    }
  },
  categoryDescription: {
    my: 2,
    py: 2
  },
  descriptionContainer: {
    color: '#2c3e50',
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 500
  },
  pagination: {
    justifyContent: 'center',
    position: 'relative'
  },
  cardWithDetail: {
    margin: '2px',
    width: 'auto',
    height: '320px',
    boxShadow: '3',
    position: 'relative'
  },
  cardWithoutDetail: {
    margin: '2px',
    width: 'auto',
    height: '260px',
    boxShadow: '3',
    position: 'relative'
  },
  header: {
    textAlign: 'center',
    background: 'linear-gradient(to right, #1e3c72, #2a5298)',
    color: '#fff',
    padding: '4px',
    boxShadow: 'inset 0 -1px 0 0 #e0e0e0',
    fontSize: '1.1rem',
    fontWeight: '200'
  },
  cardContent: {
    position: 'relative',
    textAlign: 'center'
  },
  cardComp: {
    textAlign: 'center'
  },
  deviceImage: {
    objectFit: 'contain',
    maxWidth: '200px',
    maxHeight: '200px',
    margin: 'auto',
    display: 'block'
  },
  secondQuestion: {
    marginTop: '1rem'
  },
  orgCheckBox: {
    position: 'relative',
    left: -10
  },
  quantity: {
    marginTop: '0.5rem'
  },
  form: {
    paddingLeft: 3
  },
  questions: {
    paddingRight: 3
  }
};

export default QuikspecCategoryGtEntranceGuard;
