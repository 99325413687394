import { Box, Grid, Input, Slider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IContactOutput, IDeviceContactInput } from 'store/slices/devicesSlice';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTranslation } from 'react-i18next';

interface IInputDetectionSettingProps {
  selectedRelay: IDeviceContactInput;
  setNeedsSave: (needsSave: boolean) => void;
  newRelaySettings: Array<IContactOutput | IDeviceContactInput>;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<IContactOutput | IDeviceContactInput>>>;
  inputFunction: number;
}

const MIN_MS = 200;
const MAX_MS = 2000;
const MIN_SEC_IN_MS = 3000; // 3 seconds in milliseconds
const MAX_SEC_IN_MS = 600000; // 600 seconds in milliseconds

const InputDetectionSetting = ({
  selectedRelay,
  setNeedsSave,
  newRelaySettings,
  setNewRelaySettings,
  inputFunction
}: IInputDetectionSettingProps) => {
  const { t } = useTranslation();
  const milliseconds = t('Milliseconds_Text');
  const seconds = t('Seconds_Text');
  const immediate = t('Immediate_Text');
  const [timeUnit, setTimeUnit] = useState<string>('milliseconds');
  const [inputDetection, setInputDetection] = useState<number>(selectedRelay.detectionTime);
  useEffect(() => {
    // If the selected relay is changed from the parent component, update the inputDetection state.
    setInputDetection(selectedRelay.detectionTime);
  }, [selectedRelay]);

  useEffect(() => {
    // Find the relay in newRelaySettings that matches the selectedRelay's publicId.
    const matchingRelay = newRelaySettings.find((relay) => relay.publicId === selectedRelay.publicId);
    if (
      matchingRelay &&
      'detectionTime' in matchingRelay &&
      matchingRelay.detectionTime !== selectedRelay.detectionTime
    ) {
      // If a matching relay is found and its detectionTime is different from the selectedRelay's detectionTime,
      // update the inputDetection state with the new detectionTime from newRelaySettings
      setInputDetection(matchingRelay.detectionTime);
    } else {
      // If no matching relay is found or the detectionTimes are the same, use the selectedRelay's detectionTime
      setInputDetection(selectedRelay.detectionTime);
    }
  }, [newRelaySettings, selectedRelay]);

  const handleNewRelaySettings = (timer: number) => {
    if (timer !== selectedRelay.detectionTime) {
      setNeedsSave(true);
      setNewRelaySettings((prev) => {
        const newSettingsArray = prev;
        const currentRelaySettings = newSettingsArray.find((relay) => relay.publicId === selectedRelay.publicId);

        const index = newSettingsArray.findIndex((relay) => relay.publicId === selectedRelay.publicId);
        if (index !== -1) {
          if (currentRelaySettings) newSettingsArray[index] = { ...currentRelaySettings, detectionTime: timer };
        } else {
          newSettingsArray.push({ ...selectedRelay, detectionTime: timer });
        }
        return newSettingsArray;
      });
    } else {
      setNeedsSave(false);
      setNewRelaySettings((prev) => {
        return prev.filter((relay) => relay.publicId !== selectedRelay.publicId);
      });
    }
  };

  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    setInputDetection(newValue as number);
    handleNewRelaySettings(newValue as number);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputDetection(Number(event.target.value) * (timeUnit === 'seconds' ? 1000 : 1));
    handleNewRelaySettings(Number(event.target.value) * (timeUnit === 'seconds' ? 1000 : 1));
  };
  const handleBlur = () => {
    let newValue = inputDetection;
    // Adjust the validation based on the unit
    if (timeUnit === 'seconds') {
      if (newValue < MIN_SEC_IN_MS) {
        newValue = MIN_SEC_IN_MS;
      } else if (newValue > MAX_SEC_IN_MS) {
        newValue = MAX_SEC_IN_MS;
      }
    } else if (timeUnit === 'milliseconds') {
      if (newValue < MIN_MS) {
        newValue = MIN_MS;
      } else if (newValue > MAX_MS) {
        newValue = MAX_MS;
      }
      newValue = Math.round(Number(newValue) / MIN_MS) * MIN_MS;
    } else {
      newValue = 0;
    }
    setInputDetection(newValue);
  };

  const handleTimeUnit = (_: React.MouseEvent<HTMLElement>, newUnit: string) => {
    let newValue: number;
    if (newUnit === 'immediate') {
      setTimeUnit('immediate');
      newValue = 0;
    } else if (newUnit === 'seconds') {
      // If switching to seconds
      setTimeUnit('seconds');
      newValue = Math.max(MIN_SEC_IN_MS, Math.min(inputDetection, MAX_SEC_IN_MS)) / 1000; // Convert and clamp within 3-300
    } else {
      // If switching to milliseconds
      setTimeUnit('milliseconds');
      newValue = Math.max(MIN_MS, Math.min(inputDetection * 1000, MAX_MS)); // Convert and clamp within 200-2000
    }
    setInputDetection(newValue);
  };
  if (inputFunction === 1) {
    return null;
  }

  return (
    <Box sx={styles.settingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{t('Input_Detection_Time')}</Box>
        <Box sx={styles.description}> {t('Input_Detection_Time_Desc')}</Box>
      </Box>
      <Box sx={styles.sliderWrapper}>
        <Grid container spacing={4} alignItems={'center'}>
          <Grid item style={{ marginLeft: '-120px' }}>
            <AccessTimeIcon />
          </Grid>
          <Grid item xs style={{ paddingLeft: '16px' }}>
            <Slider
              value={timeUnit === 'seconds' && inputDetection > 1000 ? inputDetection / 1000 : inputDetection} // Convert to seconds if using seconds
              onChange={handleSliderChange}
              min={timeUnit === 'seconds' ? 3 : MIN_MS} // Adjusted min based on unit
              max={timeUnit === 'seconds' ? 600 : MAX_MS} // Adjusted max based on unit
              step={timeUnit === 'seconds' ? 1 : 200}
              disabled={timeUnit === 'immediate'}
            />
          </Grid>
          <Grid item xs style={{ marginRight: '-10px' }}>
            <Input
              value={timeUnit === 'seconds' && inputDetection > 1000 ? inputDetection / 1000 : inputDetection}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              disabled={timeUnit === 'immediate'}
              inputProps={{
                step: timeUnit === 'seconds' ? 1 : 200,
                min: timeUnit === 'seconds' ? 3 : MIN_MS, // Adjusted min based on unit
                max: timeUnit === 'seconds' ? 600 : MAX_MS, // Adjusted max based on unit
                type: 'number',
                'aria-labelledby': 'input-slider'
              }}
            />
          </Grid>
          <Grid item xs style={{ marginLeft: '-100px' }}>
            <ToggleButtonGroup value={timeUnit} exclusive onChange={handleTimeUnit}>
              <ToggleButton value="miliseconds" sx={styles.toggleButton}>
                {milliseconds}
              </ToggleButton>
              <ToggleButton value="seconds" sx={styles.toggleButton}>
                {seconds}
              </ToggleButton>
              <ToggleButton value="immediate" sx={styles.toggleButton}>
                {immediate}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  sliderWrapper: {
    width: '40%',
    marginLeft: '-10px',
    paddingLeft: '10px'
  },
  toggleButton: {
    textTransform: 'none'
  }
};

export default InputDetectionSetting;
