import React from 'react';
import {
  DataGrid,
  GridCellModesModel,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  MuiEvent,
  useGridApiRef
} from '@mui/x-data-grid';
import {
  TimeSelectorType,
  handleRowClick,
  handleCellModesModelChange,
  handleCellClick,
  RelayNumber,
  rowData,
  useDataGridHandlers,
  useGridRowPopover
} from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/common';
import StationNameNumberCell from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/cells/StationNameNumberCell';
import CheckedCheckbox from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/cells/CheckedCheckbox';
import { useTranslation } from 'react-i18next';
import CustomGridToolbarWithToggle from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/headers/CustomGridToolbarWithToggle';
import { RelayDelayColHeader } from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/headers/RelayDelayColHeader';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Check } from '@mui/icons-material';

const PickupFloorDataGrid: React.FC = () => {
  const dataGridRef = React.useRef<HTMLDivElement>(null);
  const apiRef = useGridApiRef();
  const [timeSelectorType, setTimeSelectorType] = React.useState<TimeSelectorType>('s');
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const unitsList = useSelector((state: RootState) => state.units.UnitList);
  const unitsListByType = useSelector((state: RootState) => state.units.UnitListByType);

  const generateRowData = () => {
    const rowData: { id: string; unitName: string; unitNumber: string }[] = [];

    const addUnitsToRowData = (unitType: string | number) => {
      unitsListByType[unitType].forEach((unitId) => {
        const unit = unitsList[unitId];
        const relayData: { [key: string]: { enabled: boolean; delay: number } } = {};

        //TODO: This is a hardcoded value, we need to get the number of relays from the backend
        for (let i = 1; i <= 20; i++) {
          relayData[`relay_${i}`] = { enabled: false, delay: 0 };
        }

        rowData.push({
          id: unitId,
          unitName: unit.unitName,
          unitNumber: unit.unitNumber,
          ...relayData
        });
      });
    };

    ['Residential', 'Commercial', 'Guard'].forEach(addUnitsToRowData);

    return rowData;
  };

  const { t } = useTranslation();

  const { submitHandler, handleUpdateRelayDelay, handleCheckCell } = useDataGridHandlers(apiRef);
  const { handlePopoverClose, handlePopoverOpen, renderPopover } = useGridRowPopover(apiRef);
  const columns: GridColDef[] = React.useMemo(() => {
    const firstColumn: GridColDef = {
      field: 'Units',
      editable: false,
      hideable: false,
      disableColumnMenu: true,
      minWidth: 100,
      pinnable: true,
      groupable: false,
      sortable: false,
      disableReorder: true,
      hideSortIcons: true,
      filterable: false,
      headerClassName: 'font-bold pl_16px',
      headerName: t('Units'),
      renderCell: (params: GridRenderCellParams) => {
        // Extracting unitName and unitNumber from params.row
        const unitName = params.row.unitName;
        const unitNumber = params.row.unitNumber;

        return <StationNameNumberCell title={unitName} subtitle={unitNumber.toString()} />;
      },
      renderEditCell: (params: GridRenderCellParams) => {
        // Extracting unitName and unitNumber from params.row
        const unitName = params.row.unitName;
        const unitNumber = params.row.unitNumber;

        return <StationNameNumberCell title={unitName} subtitle={unitNumber.toString()} />;
      },
      cellClassName: 'unselectable m-0 p-0'
    };

    const relayCols: GridColDef[] = Array.from({ length: 20 }, (_, index) => {
      const relayNum = index + 1;
      return {
        field: `relay_${relayNum}`,
        sortable: false,
        disableReorder: true,
        hideSortIcons: true,
        filterable: false,
        pinnable: true,
        groupable: false,
        disableColumnMenu: true,
        type: 'boolean',
        editable: false,
        headerClassName: 'm-0 p-0',
        hideable: false,
        cellClassName: 'm-0 p-0',
        renderHeader: (params: GridColumnHeaderParams) => {
          const r = rowData[0];
          const relay = `relay_${relayNum}` as RelayNumber;
          // Get the delay

          const delay = r[relay]?.delay || 0;
          return (
            <RelayDelayColHeader
              params={params}
              timeType={timeSelectorType}
              headerLabel={`${t('Relay')} ${relayNum}`}
              value={rowData.length > 0 ? delay : 0}
              onValueChange={(newValue: number) => {
                handleUpdateRelayDelay(params, newValue);
              }}
              suffix={timeSelectorType.toString()}
            />
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CheckedCheckbox
              key={params.id}
              initialChecked={params.row[params.field]?.enabled || false}
              onToggle={(newChecked: boolean) => {
                handleCheckCell(params, newChecked);
              }}
            />
          );
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return (
            <CheckedCheckbox
              key={params.id}
              initialChecked={params.row[params.field]?.enabled || false}
              onToggle={(newChecked: boolean) => {
                handleCheckCell(params, newChecked);
              }}
            />
          );
        }
      };
    });

    return [firstColumn, ...relayCols];
  }, [handleCheckCell, handleUpdateRelayDelay, timeSelectorType, t]);

  return (
    <>
      <DataGrid
        sx={{
          flex: 1,
          overflowX: 'scroll'
        }}
        components={{
          Toolbar: () => (
            <CustomGridToolbarWithToggle
              description={t('Select_output_Trigger_make_call_IXG')}
              valueToggle={
                // We need to set the opposite value of the current timeSelectorType
                // because the toggle will change the value after the state is updated
                [
                  { value: 's', label: t('Seconds_Text') },
                  { value: 'ms', label: t('Milliseconds_Text') }
                ]
              }
              buttonLeftIcon={<Check />}
              label={t('Time_Units')}
              buttonText={t('Button_SaveChanges')}
              submitHandler={submitHandler}
              selectedValue={timeSelectorType === 's' ? t('Seconds_Text') : t('Milliseconds_Text')}
              handleSelectChange={(event) => {
                setTimeSelectorType(event.target.value as TimeSelectorType);
              }}
            />
          )
        }}
        slotProps={{
          cell: {
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose
          }
        }}
        cellModesModel={cellModesModel}
        onRowClick={(params, event) => handleRowClick(params, event as unknown as MuiEvent<MouseEvent>)}
        onCellModesModelChange={(cellModesModel) => handleCellModesModelChange(cellModesModel, setCellModesModel)}
        showCellVerticalBorder={true}
        showColumnVerticalBorder={true}
        columns={columns}
        autoHeight={true}
        rows={generateRowData()}
        ref={dataGridRef}
        apiRef={apiRef}
        onCellClick={(params, event) => handleCellClick(params, event as React.MouseEvent, setCellModesModel)}
      />
      {renderPopover()}
    </>
  );
};

export default PickupFloorDataGrid;
