import { CloudUser, ICapability, IRole, IPermissionEnum } from '../store/slices/usersSlice';
import { useContext, useEffect } from 'react';
import { SiteContext } from '../context/SiteContext';

export enum FeatureName {
  SIMBILLING = 'simBilling',
  REMOTEMANAGEMENT = 'remoteManagement',
  QUIKSPEC = 'quikspec',
  LANGUAGESELECTOR = 'languageSelector',
  NextIX = 'next-IX'
}

export enum PermissionsContextType {
  GLOBAL = 'global',
  SITE = 'site',
  BRANCH = 'branch'
}

export const hasCapability = (
  capabilityName: string, // e.g. 'branch:view'
  contextUuid: string | null | undefined, // e.g. 'branchUUId' or 'siteUUId'
  contextType: PermissionsContextType,
  user: CloudUser
): boolean => {
  let allowCount = 0;
  let preventCount = 0;
  let ret = false;

  const { sites, getCachedBranchIds, cacheBranchData, sitesQuery } = useContext(SiteContext);

  const checkCapabilities = (capabilityList: ICapability[]) => {
    for (const capability of capabilityList) {
      if (capability.name === capabilityName) {
        if (capability.permission === IPermissionEnum.ALLOW) {
          allowCount++;
        } else if (capability.permission === IPermissionEnum.PREVENT) {
          preventCount++;
        } else if (capability.permission === IPermissionEnum.PROHIBIT) {
          return false;
        }
      }
    }
    return true;
  };

  // Fetch the sites if the cache is empty
  useEffect(() => {
    if (!getCachedBranchIds(contextUuid ?? '') && sitesQuery.isSuccess && sitesQuery.data) {
      Object.keys(sitesQuery.data).forEach((siteUuid) => {
        const site = sitesQuery.data[siteUuid];
        if (site?.dealerBranchPublicId && site?.propertyManagerBranchPublicId) {
          cacheBranchData(siteUuid, site.dealerBranchPublicId, site.propertyManagerBranchPublicId);
        }
      });
    }
  }, [contextUuid, getCachedBranchIds, sitesQuery, cacheBranchData]);

  // Check global roles
  if (user.permissions?.global?.roleList && Array.isArray(user.permissions.global.roleList)) {
    for (const role of user.permissions.global.roleList) {
      if (!checkCapabilities(role.capabilityList)) {
        return false;
      }
    }
  }

  ret = allowCount > preventCount;
  allowCount = 0;
  preventCount = 0;

  // Check branch or site contexts
  if (contextType === PermissionsContextType.BRANCH || contextType === PermissionsContextType.SITE) {
    const targetBranches: string[] = [];
    const targetRoles: IRole[] = [];

    // Use cached data if available
    const cachedBranchData = getCachedBranchIds(contextUuid ?? '');

    if (contextType === PermissionsContextType.SITE && !user.permissions.site?.[contextUuid ?? '']) {
      const site: any = sites?.[contextUuid ?? ''] ?? null;

      if (cachedBranchData) {
        if (cachedBranchData.dealerBranchPublicId) targetBranches.push(cachedBranchData.dealerBranchPublicId);
        if (cachedBranchData.propertyManagerBranchPublicId)
          targetBranches.push(cachedBranchData.propertyManagerBranchPublicId);
      } else if (site) {
        if (site.dealerBranchPublicId) targetBranches.push(site.dealerBranchPublicId);
        if (site.propertyManagerBranchPublicId) targetBranches.push(site.propertyManagerBranchPublicId);
      }
    } else if (contextType === PermissionsContextType.BRANCH && contextUuid) {
      targetBranches.push(contextUuid);
    }

    for (const branchId of targetBranches) {
      const branch = user.permissions.branch?.[branchId];
      if (branch) {
        for (const role of branch.roleList || []) {
          targetRoles.push(role);
        }
      }
    }

    for (const role of targetRoles) {
      if (!checkCapabilities(role.capabilityList)) {
        return false;
      }
    }

    if (allowCount > 0 || preventCount > 0) {
      ret = allowCount > preventCount;
    }
  }

  // Check site-specific roles
  if (contextType === PermissionsContextType.SITE && contextUuid && user.permissions.site?.[contextUuid]) {
    const site = user.permissions.site[contextUuid];
    if (site) {
      for (const role of site.roleList || []) {
        if (!checkCapabilities(role.capabilityList)) {
          return false;
        }
      }
      if (allowCount > 0 || preventCount > 0) {
        ret = allowCount > preventCount;
      }
    }
  }

  return ret;
};

export const hasFeatureAccess = (featureName: FeatureName, user: CloudUser): boolean => {
  // Ensure email checking is correct and case-insensitive
  const isAiphoneUser = user.email && user.email.toLowerCase().endsWith('@aiphone.com');

  // If non-Aiphone user, disable access to the LANGUAGESELECTOR or NextIX features entirely
  if (!isAiphoneUser && (featureName === FeatureName.LANGUAGESELECTOR || featureName === FeatureName.NextIX)) {
    return false;
  }

  // If permissions are null or roleList under any permission type is null, allow access only to QUIKSPEC
  const noGlobalRoles = !user.permissions?.global?.roleList;
  const noSiteRoles = !user.permissions?.site || Object.values(user.permissions.site).every((site) => !site?.roleList);
  const noBranchRoles =
    !user.permissions?.branch || Object.values(user.permissions.branch).every((branch) => !branch?.roleList);

  if (noGlobalRoles && noSiteRoles && noBranchRoles) {
    return featureName === FeatureName.QUIKSPEC;
  }

  const allCapabilities: ICapability[] = [];

  // Accumulate global capabilities
  if (user.permissions?.global?.roleList) {
    for (const role of user.permissions.global.roleList) {
      allCapabilities.push(...role.capabilityList);
    }
  }

  // Accumulate site capabilities
  if (user.permissions?.site) {
    for (const siteKey in user.permissions.site) {
      const site = user.permissions.site[siteKey];
      if (site) {
        for (const role of site.roleList || []) {
          allCapabilities.push(...role.capabilityList);
        }
      }
    }
  }

  // Accumulate branch capabilities
  if (user.permissions?.branch) {
    for (const branchKey in user.permissions.branch) {
      const branch = user.permissions.branch[branchKey];
      for (const role of branch.roleList || []) {
        allCapabilities.push(...role.capabilityList);
      }
    }
  }

  // Handle LANGUAGESELECTOR for Aiphone users
  if (featureName === FeatureName.LANGUAGESELECTOR && isAiphoneUser) {
    return true; // Allow Aiphone users to access the language selector
  }

  // Check if any accumulated capability matches the feature name
  for (const capability of allCapabilities) {
    if (capability.name === featureName && capability.permission === IPermissionEnum.ALLOW) {
      return true;
    }
  }

  return false;
};
