/**
 * Note: For the tests I left out translations.
 */
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { acReaderKits } from 'features/QuikSpec/Types/ACTypes';
import { AccessoryItem, Item } from 'features/QuikSpec/Types/QuikSpecTypes';
import { gtEntranceAndGuard, gtTenantStations } from 'features/QuikSpec/Types/GTTypes';
import { jvDoorStations } from 'features/QuikSpec/Types/JVTypes';
import { accessories, masterStations } from 'features/QuikSpec/Types/LEFTypes';
import { jpDoorStations } from 'features/QuikSpec/Types/JPTypes';
import { joDoorStations } from '../Types/JOTypes';

/**
 * Determines if the total quantity of relevant card reader items is within the allowed limit.
 *
 * This function filters items based on a predefined list of card reader names and calculates the total quantity
 * of these relevant items. It then compares this quantity against a predefined maximum allowable quantity.
 *
 * @param {object} values - The input collection containing various items with their quantities.
 * @return {boolean} - Returns true if the total quantity of relevant card reader items is less than or equal
 * to the allowed maximum quantity; otherwise, false.
 */
const atMostEightyCardReaders = (values: any): boolean => {
  const items = Object.values(values);
  const cardReaderNames = acReaderKits[0]?.items.map((item: Item) => item.deviceName) || [];
  const relevantItems = items.filter((item: any) => cardReaderNames.includes(item.itemName));
  const totalQuantity = relevantItems.reduce((total: number, item: any) => total + item.quantity, 0);
  return totalQuantity <= (acReaderKits[0]?.maxQuantityAllowedPerCategory ?? 80);
};

const atMostFiveHundredTenantStations = (values: any) => {
  const items = Object.values(values);
  const tenantStations = [gtTenantStations[0], gtTenantStations[1]].flatMap((elt) => elt.items);
  const tenantStationNames = tenantStations.map((item: Item) => item.deviceName);
  const relevantItems = items.filter((item: any) => tenantStationNames.includes(item.itemName));
  const totalQuantity = relevantItems.reduce((total: number, item) => total + item.quantity, 0);
  return totalQuantity <= 500;
};

const atLeastOneTenantOrGuardStation = (values: any) => {
  const items = Object.values(values);
  const tenantStations = [gtTenantStations[0], gtTenantStations[1]].flatMap((elt) => elt.items);
  const tenantStationNames = tenantStations.map((item: Item) => item.deviceName);
  const relevantItems = items.filter((item: any) => tenantStationNames.includes(item.itemName));
  const totalTenantStationQuantity = relevantItems.reduce((total: number, item) => total + item.quantity, 0);

  const totalGuardStation = values
    .filter((item) => item.category === 'Guard Stations')
    .reduce((total: number, item) => total + item.quantity, 0);

  return totalTenantStationQuantity > 0 || totalGuardStation > 0;
};

const atMostFourGuardStations = (values: any) => {
  const items = Object.values(values);
  const guardStationNames = gtEntranceAndGuard[3].items.map((item: Item) => item.deviceName);
  const relevantItems = items.filter((item) => guardStationNames.includes(item.itemName));
  const totalQuantity = relevantItems.reduce((total, item) => total + item.quantity, 0);
  return totalQuantity <= gtEntranceAndGuard[3]?.maxQuantityAllowedPerCategory;
};

const atMostSixteenEntrancePanels = (values: any) => {
  const items = Object.values(values);
  const entrancePanelNames = gtEntranceAndGuard[0].items.map((item: Item) => item.deviceName);
  const relevantItems = items.filter((item: any) => entrancePanelNames.includes(item.itemName));
  const relevantAccessories = relevantItems.reduce((accumulator, item: any) => {
    const accessories = item.accessories || [];
    return [...accumulator, ...accessories];
  }, []);
  const totalQuantity = relevantAccessories.reduce((total: number, accessory) => total + accessory.quantity, 0);
  return totalQuantity <= (gtEntranceAndGuard[0]?.maxQuantityAllowedPerCategory ?? 0);
};

const atLeastOneTenantButton = (values: any) => {
  let result = true;
  const items = Object.values(values);
  const tenantIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_TENANT');
  const videoModuleIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_MODULE_VIDEO_ENTRANCE');
  const videoModuleFlushIndex = items[videoModuleIndex].accessories.findIndex(
    (item: Yup.AnyObject) => item.accessoryName === 'Flush Mount'
  );
  const videoModuleSurfaceIndex = items[videoModuleIndex].accessories.findIndex(
    (item: Yup.AnyObject) => item.accessoryName === 'Surface Mount'
  );
  const audioModuleIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_MODULE_AUDIO_ENTRANCE');
  const audioModuleFlushIndex = items[audioModuleIndex].accessories.findIndex(
    (item: Yup.AnyObject) => item.accessoryName === 'Flush Mount'
  );
  const audioModuleSurfaceIndex = items[audioModuleIndex].accessories.findIndex(
    (item: Yup.AnyObject) => item.accessoryName === 'Surface Mount'
  );
  const videoButtonIndex = items.findIndex((item: Yup.AnyObject) => item.itemName === 'MODULE_VIDEO_ENTRANCE_KEYPAD');
  const audioButtonIndex = items.findIndex((item: Yup.AnyObject) => item.itemName === 'MODULE_AUDIO_ENTRANCE_KEYPAD');
  const numberOfEntrance =
    items[videoModuleIndex].accessories[videoModuleFlushIndex].quantity +
    items[videoModuleIndex].accessories[videoModuleSurfaceIndex].quantity +
    items[audioModuleIndex].accessories[audioModuleFlushIndex].quantity +
    items[audioModuleIndex].accessories[audioModuleSurfaceIndex].quantity;
  if (numberOfEntrance > 0) {
    // When button, quantity is 0
    if (items[videoButtonIndex].quantity + items[audioButtonIndex].quantity <= 0) {
      if (items[tenantIndex].quantity <= 0) {
        result = false;
      }
    }
  }
  return result;
};

const atLeastOneAccessoryQuantityGreaterThanZero = (values: any) => {
  const items = Object.values(values);
  const accessories = items.reduce((accumulator: AccessoryItem[], item: any) => {
    const itemAccessories = item.accessories || [];
    return [...accumulator, ...itemAccessories];
  }, []);
  return accessories.some((accessory: AccessoryItem) => accessory.quantity ?? 0 > 0);
};

// Function to check if at least one quantity is greater than 0
const atLeastOneQuantityGreaterThanZero = (values: any) => {
  const items = Object.values(values);
  return items.some((item: any) => item.quantity > 0);
};

const atLeastOneQuantityGreaterThanZeroJP = (values: any) => {
  const items = Object.values(values);
  return items.some((item: any) => item.itemName === 'JP-4MED' && item.quantity > 0);
};

const atLeastOneQuantityGreaterThanZeroIXIXG = (values: any) => {
  const items = Object.values(values);
  const atLeastOneMainItem = items.some((item: any) => item.quantity > 0 && item.itemName !== 'LIFETIME_LICENSE');
  const totalAccessories = items.reduce(
    (total, item) => total + item.accessories.reduce((total, item) => total + item.quantity, 0),
    0
  );
  return atLeastOneMainItem || totalAccessories > 0;
};

const atMostOneDVFAccessoryJO = (values: any) => {
  const items = Object.values(values);
  const DVFItem: any = items.find((item: any) => item.itemName === 'JO-DVF');
  let totalQuantity = 0;
  if (DVFItem) {
    const accessories = DVFItem.accessories;
    if (accessories) {
      totalQuantity = accessories.reduce((total: number, accessory: any) => total + accessory.quantity, 0);
    }
  } else totalQuantity = 0;
  return totalQuantity <= 1;
};

const atLeastOneDoorStationJO = (values: any) => {
  const items = Object.values(values);
  const dvfOrDvItems = items.filter(
    (item: any) => item.itemName === 'JO-DVF' || item.itemName === 'JO-DV' || item.itemName === 'JO-DA'
  );
  return dvfOrDvItems.some((item: any) => {
    if (item.itemName === 'JO-DVF') {
      if (item.accessories && item.accessories.length > 0) {
        return item.accessories.some((accessory: any) => accessory.quantity > 0);
      }
    }
    return item.quantity > 0;
  });
};

//Function to check if there are no more than 2 Door Stations for a JO system
const atMostTwoDoorStationsJO = (values: any) => {
  const items = Object.values(values);
  const doorStationNames = joDoorStations[0].items.map((item: Item) => item.deviceName);
  const relevantItems = items.filter((item) => doorStationNames.includes(item.itemName));
  const totalQuantity: number = relevantItems.reduce((total: number, item: any) => total + item.quantity, 0);
  return totalQuantity <= (joDoorStations[0]?.maxQuantityAllowedPerCategory ?? 1);
};

const atLeastOneQuantityGreaterThanZeroGT = (values: any) => {
  const items = Object.values(values);
  return items.some((item: any) => item.quantity > 0) || atLeastOneAccessoryQuantityGreaterThanZero(values);
};

// Function to check if at least one quantity is greater than 0
const atLeastOneMasterStationLEF = (values: any) => {
  const items = Object.values(values);
  const masterStationsNames = masterStations.flatMap((station) => station.items.map((item) => item.deviceName));
  const relevantItems = items.filter((item: any) => masterStationsNames.includes(item.itemName));
  return relevantItems.some((item: any) => item.quantity > 0);
};

const atMostFiveMasterStations = (values: any) => {
  const totalMasterStations = values
    .filter((item) => item.category === 'Master Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  return totalMasterStations <= 5;
};

const atMostThreeDoorStations = (values: any) => {
  const totalVideoDoorStations = values
    .filter((item) => item.category === 'Audio/Video Door Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const totalAudioDoorStations = values
    .filter((item) => item.category === 'Audio Only Door Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  return totalVideoDoorStations + totalAudioDoorStations <= 3;
};

//Function to check if there are no more than 2 Door Stations for a JO system
const atMostTwoDoorStations = (values: any) => {
  const items = Object.values(values);
  const doorStationNames = jvDoorStations[0].items.map((item: Item) => item.deviceName);
  const relevantItems = items.filter((item: any) => doorStationNames.includes(item.itemName));
  const totalQuantity: number = relevantItems.reduce((total: number, item: any) => total + item.quantity, 0);
  return totalQuantity <= (jvDoorStations[0]?.maxQuantityAllowedPerCategory ?? 1);
};

const atMostOneDVFAccessory = (values: any) => {
  const items = Object.values(values);
  const DVFItem: any = items.find((item: any) => item.itemName === 'JV-DVF');
  let totalQuantity = 0;
  if (DVFItem) {
    const accessories = DVFItem.accessories;
    if (accessories) {
      totalQuantity = accessories.reduce((total: number, accessory: any) => total + accessory.quantity, 0);
    }
  } else totalQuantity = 0;
  return totalQuantity <= 1;
};

const atLeastOneDoorStationJV = (values: any) => {
  const items = Object.values(values);
  const dvfOrDvItems = items.filter((item: any) => item.itemName === 'JV-DVF' || item.itemName === 'JV-DV');
  return dvfOrDvItems.some((item: any) => {
    if (item.itemName === 'JV-DVF') {
      if (item.accessories && item.accessories.length > 0) {
        return item.accessories.some((accessory: any) => accessory.quantity > 0);
      }
    }
    return item.quantity > 0;
  });
};

const atLeastOneDoorStationKB = (values: any) => {
  const items = Object.values(values);
  const totalVideoDoorStations = items
    .filter((item) => item.category === 'Audio/Video Door Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const totalAudioDoorStations = items
    .filter((item) => item.category === 'Audio Only Door Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  return totalVideoDoorStations + totalAudioDoorStations >= 1;
};

const atLeastOneDoorStationJP = (values: any) => {
  const items = Object.values(values);
  const totalDoorStations = items
    .filter((item) => item.category === 'Door Stations')
    .reduce((total, item) => total + item.accessories.reduce((total, item) => total + item.quantity, 0), 0);
  return totalDoorStations > 0;
};

const systemSizeCheck = (values: any) => {
  const items = Object.values(values);
  const accessoriesNames = accessories[0].items.map((item) => item.deviceName);
  const relevantItems = items.filter((item: any) => !accessoriesNames.includes(item.itemName));
  const totalQuantity = relevantItems.reduce((total: number, item: any) => total + item.quantity, 0);
  return totalQuantity <= 11;
};

const rypaValidityCheck = (values: any) => {
  const items = Object.values(values);

  // Filter out master stations
  const masterStationItems = items.filter((item) =>
    ['3-Call Master Stations', '5-Call Master Stations', '10-Call Master Stations'].includes(item.category)
  );

  // Check if only LEF-3 or LEF-3C are selected with quantity > 0
  const selectedMasterStations = masterStationItems.filter((item) => item.quantity > 0);
  const isOnlyLEF3orLEF3C =
    selectedMasterStations.every((item) => ['LEF-3', 'LEF-3C'].includes(item.itemName)) &&
    selectedMasterStations.length > 0;
  const ryPACount = items.find((item) => item.itemName === 'RY-PA')?.quantity || 0;

  return !(ryPACount > 0 && isOnlyLEF3orLEF3C);
};

const atMostEightyPatientStations = (values: any) => {
  const totalPatientStations = values
    .filter((item) => item.category === 'Patient Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const totalCommonAreaCallStations = values
    .filter((item) => item.itemName === 'NHR-3TS')
    .reduce((total: number, item) => total + item.quantity, 0);
  return totalPatientStations + totalCommonAreaCallStations <= 80;
};

const atLeastOnePatientStation = (values: any) => {
  const totalPatientStations = values
    .filter((item) => item.category === 'Patient Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const totalCommonAreaCallStations = values
    .filter((item) => item.itemName === 'NHR-3TS')
    .reduce((total: number, item) => total + item.quantity, 0);
  return totalPatientStations + totalCommonAreaCallStations >= 1;
};

const atMostFourDoorStations = (values: any) => {
  const items = Object.values(values);
  const totalDoorStations = items
    .filter((item) => item.category === 'Door Stations')
    .reduce((total, item) => total + item.accessories.reduce((total, item) => total + item.quantity, 0), 0);
  return totalDoorStations <= jpDoorStations[0]?.maxQuantityAllowedPerCategory;
};

// Ax Master Stations: At least one AX Master Station (AX-8MV or AX-8M) is required
const atLeastOneAXMasterStation = (values: any): boolean => {
  const items = Object.values(values);
  const masterStationItems = items.filter((item: any) => item.category === 'Master Stations');
  return masterStationItems.some((item: any) => item.quantity > 0);
};

const useQuikSpecFormValidationSchema = () => {
  // Initialize the translation service
  const { t } = useTranslation();

  /**
   * Validation schema for the QuikSpec AC form.
   *
   * This schema is used to validate the form fields before submission. It ensures the following rules are met:
   *
   * - `quikspecName`: Must be a non-empty string, with validation message defined by the translatable key 'QuikSpec_name_is_required'.
   * - `marketType`: Must be a non-empty string, with validation message 'Market Type is required'.
   * - `items`: Must be an array of objects where:
   *   - Each object must contain:
   *     - `itemName`: Must be a non-empty string, with validation message defined by the translatable key 'Item_Name_is_Required'.
   *     - `quantity`: Must be a number greater than or equal to 0, with validation message 'Quantity must be greater than or equal to 0' and it is required, with validation message 'Quantity is required'.
   * - Additionally, the `items` array must pass the following custom tests:
   *   - `atLeastOneQuantity`: At least one item must have a quantity greater than 0, with validation message 'At least one item must have quantity greater than 0'.
   *   - `atMostEightyCardReaders`: At most 80 card readers can be added, with validation message 'At most 80 card readers can be added'.
   *
   * Dependent on the translation function `t` for translatable error messages.
   */
  const ac = Yup.object().shape({
    quikspecName: Yup.string().required(t('QuikSpec_name_is_required')),
    marketType: Yup.string().required(t('Market_Type_is_required')),
    items: Yup.array()
      .of(
        Yup.object().shape({
          itemName: Yup.string().required(t('Item_Name_is_Required')),
          quantity: Yup.number()
            .min(0, t('Quantity_must_be_greater_than_or_equal_to_0'))
            .required(t('Quantity_is_required'))
        })
      )
      .test(
        'atLeastOneQuantity',
        t('At_least_one_item_must_have_quantity_greater_than_0'),
        atLeastOneQuantityGreaterThanZero
      )
      .test('atMostEightyCardReaders', t('At_most_80'), atMostEightyCardReaders)
  });
  /**
   * Schema for validating QuikSpec AX form.
   *
   * This schema is defined using Yup and ensures the form data adheres to the following rules:
   *
   * - quikspecName: A required string field.
   * - marketType: A required string field.
   * - items: An array of objects where each object must contain:
   *   - itemName: A required string field.
   *   - quantity: A required number that must be at least 0.
   *
   * The items array undergoes additional validation:
   * - At least one item must have a quantity greater than 0.
   * - No more than 8 master stations can be included in the system.
   * - No more than 128 stations can be included in the system.
   *
   * Dependencies:
   * - atLeastOneQuantityGreaterThanZero: A function to validate that at least one item has a quantity greater than 0.
   * - atMostEightMasterStations: A function to validate that no more than 8 master stations are included.
   * - atMostHundredTwentyEightStations: A function to validate that no more than 128 stations are included.
   * - t: A localization function used for error messages.
   */
  const ax = Yup.object().shape({
    quikspecName: Yup.string().required(t('QuikSpec_name_is_required')),
    marketType: Yup.string().required(t('Market_Type_is_required')),
    items: Yup.array()
      .of(
        Yup.object().shape({
          itemName: Yup.string().required(t('Item_Name_is_Required')),
          quantity: Yup.number()
            .min(0, t('Quantity_must_be_greater_than_or_equal_to_0'))
            .required(t('Quantity_is_required'))
        })
      )
      .test(
        'atLeastOneQuantity',
        'At least one item must have quantity greater than 0',
        atLeastOneQuantityGreaterThanZero
      )
      .test('', 'Up to 8 master stations can be in the system', atMostEightMasterStations)
      .test('', 'Up to 128 stations can be in the system', atMostHundredTwentyEightStations)
      .test('atLeastOneAXMasterStation', t('At_least_one_AX_Master_station'), atLeastOneAXMasterStation)
  });

  const gt = Yup.object().shape({
    quikspecName: Yup.string().required(t('QuikSpec_name_is_required')),
    marketType: Yup.string().required(t('Market_Type_is_required')),
    items: Yup.array()
      .of(
        Yup.object().shape({
          itemName: Yup.string().required(t('Item_Name_is_Required')),
          quantity: Yup.number()
            .min(0, t('Quantity_must_be_greater_than_or_equal_to_0'))
            .required(t('Quantity_is_required'))
        })
      )
      .test(
        'atLeastOneQuantity',
        'At least one item with or without accessories must have quantity greater than 0',
        atLeastOneQuantityGreaterThanZeroGT
      )
      .test('atMostFourGuardStations', 'There can only be at most 4 Guard stations', atMostFourGuardStations)
      .test('atMostSixteenEntrancePanels', 'There can only be at most 16 Entrance Panels', atMostSixteenEntrancePanels)
      .test('atLeastOneTenantButton', 'There is no tenant called from Module Entrance Panels', atLeastOneTenantButton)
      .test(
        'atMostFiveHundredTenantStations',
        'There can only be at most 500 Tenant Stations',
        atMostFiveHundredTenantStations
      )
      .test(
        'atLeastOneTenantOrGuardStation',
        'At least one Tenant or Guard Station must be selected',
        atLeastOneTenantOrGuardStation
      )
  });

  const twoWire = Yup.object().shape({
    quikspecName: Yup.string().required(t('QuikSpec_name_is_required')),
    marketType: Yup.string().required(t('Market_Type_is_required')),
    items: Yup.array().of(
      Yup.object().shape({
        itemName: Yup.string().required(t('Item_Name_is_Required')),
        quantity: Yup.number()
          .min(0, t('Quantity_must_be_greater_than_or_equal_to_0'))
          .required(t('Quantity_is_required'))
      })
    )
  });

  const jo = Yup.object().shape({
    quikspecName: Yup.string().required('Quikspec Name is required'),
    marketType: Yup.string().required('Market Type is required'),
    items: Yup.array()
      .of(
        Yup.object().shape({
          itemName: Yup.string().required('Item Name is required'),
          quantity: Yup.number().min(0, 'Quantity must be greater than or equal to 0').required('Quantity is required')
        })
      )
      .test(
        'atLeastOneQuantity',
        'At least one item must have quantity greater than 0',
        atLeastOneQuantityGreaterThanZero
      )
      .test('atMostTwoDoorStations', 'At most two Door stations are allowed', atMostTwoDoorStationsJO)
      .test('atMostOneDVFAccessory', 'At most one DVF accessory is allowed', atMostOneDVFAccessoryJO)
      .test('atLeastOneDoorStation', 'At least one Door Station must be selected', atLeastOneDoorStationJO)
  });

  const jv = Yup.object().shape({
    quikspecName: Yup.string().required(t('QuikSpec_name_is_required')),
    marketType: Yup.string().required(t('Market_Type_is_required')),
    items: Yup.array()
      .of(
        Yup.object().shape({
          itemName: Yup.string().required(t('Item_Name_is_Required')),
          quantity: Yup.number()
            .min(0, t('Quantity_must_be_greater_than_or_equal_to_0'))
            .required(t('Quantity_is_required'))
        })
      )
      .test(
        'atLeastOneQuantity',
        'At least one item must have quantity greater than 0',
        atLeastOneQuantityGreaterThanZero
      )
      .test('atMostTwoDoorStations', 'At most two Door stations are allowed', atMostTwoDoorStations)
      .test('atMostOneDVFAccessory', 'At most one DVF accessory is allowed', atMostOneDVFAccessory)
      .test('atLeastOneDoorStation', 'At least one Door Station must be selected', atLeastOneDoorStationJV)
  });

  const lef = Yup.object().shape({
    quikspecName: Yup.string().required(t('QuikSpec_name_is_required')),
    marketType: Yup.string().required(t('Market_Type_is_required')),
    items: Yup.array()
      .of(
        Yup.object().shape({
          itemName: Yup.string().required(t('Item_Name_is_Required')),
          quantity: Yup.number()
            .min(0, t('Quantity_must_be_greater_than_or_equal_to_0'))
            .required(t('Quantity_is_required'))
        })
      )
      .test('atLeastOneQuantity', 'At least one Master Station is required', atLeastOneMasterStationLEF)
      .test(
        'SystemSizeCheck',
        'The LEF system supports 11 total stations. Please reassess you requirements or look into using the IX series.',
        systemSizeCheck
      )
      .test(
        'rypaValidityCheck',
        'LEF-3 and LEF-3C master stations do not support door release. Remove the RY-PA or choose a different master station.',
        rypaValidityCheck
      )
  });

  const nhx = Yup.object().shape({
    quikspecName: Yup.string().required(t('QuikSpec_name_is_required')),
    marketType: Yup.string().required(t('Market_Type_is_required')),
    items: Yup.array()
      .of(
        Yup.object().shape({
          itemName: Yup.string().required(t('Item_Name_is_Required')),
          quantity: Yup.number()
            .min(0, t('Quantity_must_be_greater_than_or_equal_to_0'))
            .required(t('Quantity_is_required'))
        })
      )
      .test('', 'Up to 80 patient stations can be in the system', atMostEightyPatientStations)
      .test('', 'At least one patient station is required', atLeastOnePatientStation)
  });

  const ix = Yup.object().shape({
    quikspecName: Yup.string().required(t('QuikSpec_name_is_required')),
    marketType: Yup.string().required(t('Market_Type_is_required')),
    items: Yup.array()
      .of(
        Yup.object().shape({
          itemName: Yup.string().required(t('Item_Name_is_Required')),
          quantity: Yup.number()
            .min(0, t('Quantity_must_be_greater_than_or_equal_to_0'))
            .required(t('Quantity_is_required'))
        })
      )
      .test(
        'atLeastOneQuantity',
        'At least one item must have quantity greater than 0',
        atLeastOneQuantityGreaterThanZeroIXIXG
      )
  });

  const kb = Yup.object().shape({
    quikspecName: Yup.string().required(t('QuikSpec_name_is_required')),
    marketType: Yup.string().required(t('Market_Type_is_required')),
    items: Yup.array()
      .of(
        Yup.object().shape({
          itemName: Yup.string().required(t('Item_Name_is_Required')),
          quantity: Yup.number()
            .min(0, t('Quantity_must_be_greater_than_or_equal_to_0'))
            .required(t('Quantity_is_required'))
        })
      )
      .test('', 'Up to 5 master stations can be in the system', atMostFiveMasterStations)
      .test('', 'Up to 3 door stations can be in the system', atMostThreeDoorStations)
      .test('', 'At least one door station is required', atLeastOneDoorStationKB)
  });

  const jp = Yup.object().shape({
    quikspecName: Yup.string().required(t('QuikSpec_name_is_required')),
    marketType: Yup.string().required(t('Market_Type_is_required')),
    items: Yup.array()
      .of(
        Yup.object().shape({
          itemName: Yup.string().required(t('Item_Name_is_Required')),
          quantity: Yup.number()
            .min(0, t('Quantity_must_be_greater_than_or_equal_to_0'))
            .required(t('Quantity_is_required'))
        })
      )
      .test(
        'atLeastOneQuantity',
        'At least JP-4MED must have quantity greater than 0',
        atLeastOneQuantityGreaterThanZeroJP
      )
      .test('atLeastOneDoorStation', 'At least one door station is required', atLeastOneDoorStationJP)
      .test('atMostFourDoorStations', 'There can only be at most 4 door stations', atMostFourDoorStations)
  });

  return {
    ac,
    ax,
    gt,
    twoWire,
    jo,
    jv,
    lef,
    nhx,
    ix,
    jp,
    kb
  };
};

const atMostEightMasterStations = (values: any) => {
  const totalMasterStations = values
    .filter((item: Yup.AnyObject) => item.category === 'Master Stations')
    .reduce((total: number, item: Yup.AnyObject) => total + item.quantity, 0);
  return totalMasterStations <= 8;
};

const atMostHundredTwentyEightStations = (values: any) => {
  const totalMasterStations = values
    .filter((item: Yup.AnyObject) => item.category === 'Master Stations')
    .reduce((total: number, item: Yup.AnyObject) => total + item.quantity, 0);
  const totalSubMasterStations = values
    .filter((item: Yup.AnyObject) => item.category === 'Sub Stations')
    .reduce((total: number, item: Yup.AnyObject) => total + item.quantity, 0);
  const totalVideoDoorStations = values
    .filter((item: Yup.AnyObject) => item.category === 'Audio/Video Door Stations')
    .reduce((total: number, item: Yup.AnyObject) => total + item.quantity, 0);
  const totalAudioDoorStations = values
    .filter((item: Yup.AnyObject) => item.category === 'Audio Only Door Stations')
    .reduce((total: number, item: Yup.AnyObject) => total + item.quantity, 0);
  return totalMasterStations + totalSubMasterStations + totalVideoDoorStations + totalAudioDoorStations <= 128;
};

export default useQuikSpecFormValidationSchema;
