import React from 'react';
import { Box } from '@mui/material';

import { useQuikSpecNotes } from 'features/QuikSpec/Hooks/quikSpecDevices';

interface Props {
  systemName: string;
}

const QuikspecNote = ({ systemName }: Props) => {
  const Note = useQuikSpecNotes();

  const isValidNoteItemKey = React.useCallback(
    (key: string): key is keyof typeof Note.Link => {
      return Object.prototype.hasOwnProperty.call(Note.Link as object, key);
    },
    [Note]
  );

  return (
    <Box sx={{ pb: 4, fontSize: '0.8rem' }}>
      <div>
        <strong>
          {Note.Component1}
          <em>{Note.Component2}</em>
        </strong>
      </div>
      {isValidNoteItemKey(systemName) && (
        <div>
          {Note.Component3}
          <a href={Note.Link[systemName].URL} target="_blank">
            {Note.Link[systemName].text}
          </a>
          .
        </div>
      )}
      <p />
      <div>
        {Note.Component4}
        <a href="https://www.aiphone.com/sales/regional-sales" target="_blank">
          {Note.Component5}
        </a>
        {Note.Component6}
        <a href="mailto:tech@aiphone.com?subject=QuikSpec Question">{Note.Component7}</a>
      </div>
    </Box>
  );
};

export default QuikspecNote;
