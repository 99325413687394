import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  FormControl,
  TextField,
  Button,
  CircularProgress,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Box,
  Autocomplete
} from '@mui/material';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  useGetUsersByBranchPublicIdQuery,
  useGetAssignableRolesQuery,
  useInviteSiteUserMutation
} from 'services/aiphoneCloud';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { CloudUser, ContextType } from 'store/slices/usersSlice';
import SiteRoleDescriptions from '../SiteRoleDescriptions';
import { useTranslation } from 'react-i18next';

interface IRole {
  publicId: string;
  roleName: string;
}

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onInviteSuccess: () => void;
  userEmailList: string[];
}

interface FormValues {
  userInput: string;
  userRole: string;
}

const InviteDialog: React.FC<Props> = ({ userEmailList, isOpen, setIsOpen, onInviteSuccess }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [siteStateName, setSiteStateName] = useState<string | null>(null);
  const [siteCountryName, setSiteCountryName] = useState<string | null>(null);

  const user = useSelector((state: RootState) => state.users.currentUser);
  const publicId = useSelector((state: RootState) => state.site.siteInfo?.publicId);
  const branchData = useSelector((state: RootState) => state.branches);
  const currentSite = useSelector((state: RootState) => state.site.siteInfo);

  // Reset success and error messages when the dialog opens
  useEffect(() => {
    if (isOpen) {
      setSuccessMessage(null);
      setErrorMessage(null);
    }
  }, [isOpen]);

  // Fetch state and country from sessionStorage
  useEffect(() => {
    const enumList = sessionStorage.getItem('aiphoneCloud_enumList');
    if (enumList && currentSite?.stateId) {
      const parsedData = JSON.parse(enumList);

      // Access state and country within the parsed data
      const stateData = parsedData.state;
      const countryData = parsedData.country;

      if (stateData && countryData) {
        const matchedState = stateData[currentSite.stateId];
        if (matchedState) {
          setSiteStateName(matchedState.isoCode);

          const matchedCountry = countryData[matchedState.countryId];
          if (matchedCountry) {
            setSiteCountryName(matchedCountry.alpha3Code);
          }
        }
      }
    }
  }, [currentSite?.stateId]);

  const validationSchema = Yup.object({
    userInput: Yup.string().required(t('Please_select_a_user')),
    userRole: Yup.string().required(t('User_role_is_required'))
  });

  // Fetch user and role data
  const { data: usersData } = useGetUsersByBranchPublicIdQuery({
    qty: '-1',
    page: '0',
    publicId: branchData.currentBranch?.publicId
  });

  const { data: rolesData, isFetching: isRolesFetching } = useGetAssignableRolesQuery({
    contextPublicId: publicId,
    contextType: ContextType.SITE
  });
  const [inviteUser] = useInviteSiteUserMutation();

  const handleInvite = async (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      const selectedUser = usersData?.userList[values.userInput] as CloudUser | undefined;
      if (selectedUser) {
        const inviteUserPayload = {
          sitePublicId: publicId,
          rolePublicId: values.userRole,
          roleName: rolesData?.find((role: IRole) => role.publicId === values.userRole)?.roleName,
          email: selectedUser.email.toLowerCase(),
          invitedByFirstName: user?.firstName,
          invitedByLastName: user?.lastName,
          invitedSiteName: currentSite?.siteName,
          invitedSiteAddress: currentSite?.siteAddress,
          invitedSiteAddress2: currentSite?.siteAddress2,
          invitedSiteCity: currentSite?.siteCity,
          invitedSiteState: siteStateName || 'N/A', // Use the state name from sessionStorage or default
          invitedSiteZip: currentSite?.siteZip,
          invitedSiteCountry: siteCountryName || 'N/A', // Use the country name from sessionStorage or default
          languageId: 2,
          countryId: 2
        };

        await inviteUser(inviteUserPayload).unwrap();
        setSuccessMessage(t('User_invited_successfully'));
        onInviteSuccess();

        setTimeout(() => {
          setIsOpen(false);
        }, 3000);
      } else {
        setErrorMessage(t('Invalid_input_Please_select_a_valid_user'));
      }
    } catch (error) {
      setErrorMessage(t('Failed_to_invite_user_Please_try_again'));
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const handleCancelClick = () => {
    setIsOpen(false);
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  return (
    <Dialog onClose={handleCancelClick} open={isOpen} maxWidth="sm" fullWidth>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>{t('Invite_Administrator')}</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={handleCancelClick}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Formik
        initialValues={{ userInput: '', userRole: '' }}
        validationSchema={validationSchema}
        onSubmit={handleInvite}
      >
        {({ errors, touched, handleBlur, handleChange, setFieldValue, values, isSubmitting }) => (
          <Form noValidate>
            <DialogContent>
              <Box sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <Autocomplete
                        options={Object.entries(usersData?.userList || {})
                          .filter(([_, user]) => {
                            const typedUser = user as CloudUser;
                            return (
                              typedUser.firstName && typedUser.lastName && !userEmailList.includes(typedUser.email)
                            );
                          })
                          .map(([userId, user]) => {
                            const typedUser = user as CloudUser;
                            return {
                              id: userId,
                              label: `${typedUser.firstName} ${typedUser.lastName} (${typedUser.email})`
                            };
                          })}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, value) => {
                          if (value?.id) {
                            setFieldValue('userInput', value.id);
                          }
                          setErrorMessage(null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('Select_User')}
                            variant="outlined"
                            error={touched.userInput && !!errors.userInput}
                            helperText={touched.userInput && errors.userInput}
                            onBlur={handleBlur}
                            sx={styles.inputField}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={touched.userRole && !!errors.userRole}
                      disabled={isRolesFetching || !rolesData || rolesData.length === 0}
                      sx={styles.inputField}
                    >
                      <InputLabel id="user-role-select-input-label">{t('Select_User_Role')}</InputLabel>
                      <Field
                        as={Select}
                        name="userRole"
                        label={t('Select_User_Role')}
                        fullWidth
                        variant="outlined"
                        size="small"
                        onBlur={(e: any) => {
                          handleBlur(e);
                          setErrorMessage(null);
                        }}
                        onChange={(e: any) => {
                          handleChange(e);
                          setErrorMessage(null);
                        }}
                        labelId="user-role-select-input-label"
                      >
                        {Array.isArray(rolesData) && rolesData.length > 0 ? (
                          rolesData.map((role: IRole) => (
                            <MenuItem key={role.publicId} value={role.publicId}>
                              {role.roleName}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" disabled>
                            {t('No_roles_available')}
                          </MenuItem>
                        )}
                      </Field>
                      <FormHelperText>{touched.userRole && errors.userRole}</FormHelperText>
                    </FormControl>
                  </Grid>

                  {/* Add SiteRoleDescriptions component to show role description */}
                  {values.userRole && (
                    <Grid item xs={12}>
                      <SiteRoleDescriptions
                        roleName={rolesData?.find((role: IRole) => role.publicId === values.userRole)?.roleName || ''}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              {errorMessage && (
                <Typography variant="body2" color="error" align="center">
                  {errorMessage}
                </Typography>
              )}
              {successMessage && (
                <Typography variant="body2" color="success" align="center">
                  {successMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelClick} variant="contained">
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || loading}
                startIcon={loading && <CircularProgress size={20} />}
              >
                {t('Invite_Administrator')}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const styles = {
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'gray',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray'
      },
      '&:hover fieldset': {
        '& .enabled': {
          borderColor: '#003366'
        }
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  }
};

export default InviteDialog;
