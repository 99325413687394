import { IUnitList } from 'store/slices/unitsSlice';

export const generateAppList = (
  selectedUnit: string,
  appsToBeCreated: number,
  unitList: IUnitList,
  buildingList: any,
  existingAppsCount: number,
  t: (key: string) => string
) => {
  const appList = [];
  const unit = unitList[selectedUnit];
  const buildingNumber = buildingList[unit.buildingPublicId]?.buildingSequentialNumber;
  const baseUnitNumber = unit.unitNumber;
  const unitHasApps = existingAppsCount > 0;

  for (let i = 0; i < appsToBeCreated; i++) {
    const appIndex = unitHasApps ? existingAppsCount + i : i;
    const newApp = {
      appData: {
        stationNumber: `${buildingNumber}${baseUnitNumber}00${appIndex}`,
        stationName: `${t('Mobile_App')}${unitHasApps ? baseUnitNumber : ''}${appIndex}`,
        unitPublicId: selectedUnit
      }
    };

    appList.push(newApp);
  }
  return appList;
};
