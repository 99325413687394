import {
  AddBusiness as AddBusinessIcon,
  Apartment as ApartmentIcon,
  Assignment as AssignmentIcon,
  Dashboard as DashboardIcon,
  Handyman as HandymanIcon,
  Receipt as ReceiptIcon,
  SimCard as SimCardIcon,
  SupportAgent as SupportAgentIcon,
  CalendarMonth as CalendarMonth
} from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import { Box, Grid, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import { CSSObject, Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { usePermission } from 'context/PermissionContext';
import { RootState } from 'store';
import { getCurrentUser } from 'store/slices/usersSlice';
import { FeatureName, PermissionsContextType } from '../permissions/utils';
import { useTranslation } from 'react-i18next';

interface SideBarMenuItemsProps {
  hasGlobalRole: boolean;
  isProductionAccount: boolean;
}

const SideBarMenuItems = ({ hasGlobalRole }: SideBarMenuItemsProps) => {
  const { t } = useTranslation(); // Initialize translation hook
  const currentUser = useSelector((state: RootState) => state.users.currentUser);
  const currentBranch = useSelector((state: RootState) => state.branches.currentBranch);
  const location = useLocation();
  const { isAllowedTo, hasFeatureAccess } = usePermission();

  const canAccessAdminTools =
    isAllowedTo('usersTab:view', null, PermissionsContextType.GLOBAL) &&
    isAllowedTo('organizationsTab:view', null, PermissionsContextType.GLOBAL);

  let canAccessBranchUsersTab = false;
  if (!canAccessAdminTools) {
    const branchPermissions = currentUser?.permissions?.branch || {};
    if (branchPermissions) {
      const firstBranchKey = Object.keys(branchPermissions)[0];
      const firstBranchPublicId = branchPermissions[firstBranchKey]?.roleList?.[0]?.publicId || null;
      canAccessBranchUsersTab = isAllowedTo(
        'usersTab:view',
        currentBranch?.publicId || firstBranchPublicId,
        PermissionsContextType.BRANCH
      );
    }
  }

  const canAccessSimBilling = hasFeatureAccess(FeatureName.SIMBILLING);
  const canAccessRemoteManagement = hasFeatureAccess(FeatureName.REMOTEMANAGEMENT);
  const canAccessQuikSpec = hasFeatureAccess(FeatureName.QUIKSPEC);
  const canAccessNextIX = hasFeatureAccess(FeatureName.NextIX);

  return (
    <>
      <MenuItem active={location.pathname === '/'} component={<Link to="/" />} icon={<DashboardIcon />}>
        <Typography variant="body2">{t('Dashboard')}</Typography> {/* Use t for translation */}
      </MenuItem>
      {hasGlobalRole && canAccessAdminTools && (
        <SubMenu
          label={t('Admin_Tools')}
          icon={<HandymanIcon />}
          rootStyles={{
            '&:hover': {
              backgroundColor: '#e2f2ff'
            },
            '.ps-submenu-content': {
              width: 'auto'
            },
            margin: 0,
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em'
          }}
        >
          <MenuItem active={location.pathname === '/users'} component={<Link to="/users" />} icon={<GroupIcon />}>
            <Typography variant="body2">{t('Manage_Users')}</Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === '/dealercompanies'}
            component={<Link to="/dealercompanies" />}
            icon={<AddBusinessIcon />}
          >
            <Typography variant="body2">{t('Manage_Organizations')}</Typography>
          </MenuItem>
        </SubMenu>
      )}
      {!canAccessAdminTools && canAccessBranchUsersTab && (
        <MenuItem active={location.pathname === '/branch-users'} component={<Link to="/users" />} icon={<GroupIcon />}>
          <Typography variant="body2">{t('Branch_Users')}</Typography>
        </MenuItem>
      )}
      {canAccessQuikSpec && (
        <MenuItem active={location.pathname === '/quikspec'} component={<Link to="/quikspec" />} icon={<ReceiptIcon />}>
          <Typography variant="body2">{t('QuikSpec')}</Typography>
        </MenuItem>
      )}
      {canAccessSimBilling && (
        <MenuItem
          active={location.pathname === '/simbilling'}
          component={<Link to="/simbilling" />}
          icon={<SimCardIcon />}
        >
          <Typography variant="body2">{t('Sim_Billing')}</Typography>
        </MenuItem>
      )}
      {canAccessRemoteManagement && (
        <MenuItem
          active={location.pathname === '/remotemanagement'}
          component={<Link to="/remotemanagement" />}
          icon={<ApartmentIcon />}
        >
          <Typography variant="body2">{t('Remote_Management')}</Typography>
        </MenuItem>
      )}
      {canAccessNextIX && (
        <MenuItem
          active={location.pathname === '/appCalendar'}
          component={<Link to="/appCalendar" />}
          icon={<CalendarMonth />}
        >
          <Typography variant="body2">{t('NEXT-IX.NEXT-IX')}</Typography>
        </MenuItem>
      )}
    </>
  );
};

const SideBarSkeleton = () => {
  return (
    <Box>
      <Grid container spacing={0.25}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={50} width="100%" animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={50} width="100%" animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={50} width="100%" animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={50} width="100%" animation="wave" />
        </Grid>
      </Grid>
    </Box>
  );
};

function SideBar(props: any) {
  const theme = useTheme();
  const location = useLocation();
  const currentUser = useSelector(getCurrentUser);
  const { t } = useTranslation(); // Initialize translation hook

  const hasGlobalRole = currentUser ? currentUser.permissions.global.roleList !== null : false;

  const currentUrl = window.location.href;
  const isProductionAccount = !/localhost|beta|alpha/.test(currentUrl);

  return (
    <Sidebar
      style={{
        height: 'calc(100%-64px)',
        position: 'sticky',
        top: '0',
        left: '0',
        zIndex: 1
      }}
      backgroundColor={theme.palette.neutral.light}
      collapsed={props.collapsed}
      toggled={props.toggled}
      collapsedWidth={useMediaQuery(() => theme.breakpoints.up('sm')) ? '80px' : '0px'}
    >
      <Menu
        menuItemStyles={{
          button: ({ active }) => ({
            backgroundColor: active ? '#c9e7ff' : undefined,
            color: active ? '#0071CE' : undefined,
            '&:hover': {
              backgroundColor: active ? '#c9e7ff' : '#e2f2ff'
            }
          })
        }}
      >
        {currentUser ? (
          <SideBarMenuItems hasGlobalRole={hasGlobalRole} isProductionAccount={isProductionAccount} />
        ) : (
          <SideBarSkeleton />
        )}
      </Menu>
      <Menu
        menuItemStyles={{
          button: ({ active }) => ({
            backgroundColor: active ? theme.palette.neutral.medium : undefined,
            width: '100%'
          })
        }}
        rootStyles={styles.secondMenu as CSSObject}
      >
        <Divider />
        <MenuItem
          active={location.pathname === '/support'}
          component={<Link to="/support" />}
          icon={<SupportAgentIcon />}
        >
          <Typography style={styles.overflowHidden} variant="body2">
            {t('Support')}
          </Typography>
        </MenuItem>
        <MenuItem active={location.pathname === '/legal'} component={<Link to="/legal" />} icon={<AssignmentIcon />}>
          <Typography style={styles.overflowHidden} variant="body2">
            {t('Legal_Documents')}
          </Typography>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

const styles = {
  overflowHidden: {
    overflow: 'hidden'
  },
  secondMenu: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    '@media (max-height: 700px)': {
      position: 'relative'
    }
  }
};

export default SideBar;
