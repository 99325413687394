import { Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
const QuikspecIE = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography align="center" variant="h5">
        {t('We_Will_Update_the_brand_new_QuikSpec_application_soon')}
      </Typography>
    </Box>
  );
};

export default QuikspecIE;
