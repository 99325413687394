/**
 * @description Helper functions for the gateway
 *
 */
import { useHandleGatewayCommandMutation } from 'services/aiphoneCloud';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';

/**
 * Custom error class for handling gateway-related errors.
 *
 * @extends {Error}
 */
export class GatewayError extends Error {
  /**
   * The error code associated with the gateway error.
   */
  code: string;

  /**
   * The optional site ID where the error occurred.
   */
  siteId?: string;

  /**
   * Creates an instance of GatewayError.
   *
   * @param {string} code - The error code.
   * @param {string} [message] - The error message.
   * @param {string} [siteId] - The optional site ID where the error occurred.
   */
  constructor(code: string, message?: string, siteId?: string) {
    super(message);
    this.code = code;
    this.siteId = siteId;
  }
}

enum GatewayErrorCodes {
  RM_GW_001 = 'RM-GW-001',
  RM_GW_002 = 'RM-GW-002',
  RM_GW_003 = 'RM-GW-003',
  RM_GW_004 = 'RM-GW-004',
  RM_GW_005 = 'RM-GW-005',
  RM_GW_006 = 'RM-GW-006',
  RM_GW_007 = 'RM-GW-007',
  RM_GW_008 = 'RM-GW-008',
  RM_GW_009 = 'RM-GW-009',
  RM_GW_010 = 'RM-GW-010',
  RM_GW_011 = 'RM-GW-011',
  RM_GW_012 = 'RM-GW-012',
  RM_GW_013 = 'RM-GW-013',
  RM_GW_014 = 'RM-GW-014',
  RM_GW_015 = 'RM-GW-015',
  RM_GW_016 = 'RM-GW-016',
  RM_GW_017 = 'RM-GW-017',
  RM_GW_018 = 'RM-GW-018',
  RM_GW_019 = 'RM-GW-019',
  RM_GW_020 = 'RM-GW-020',
  RM_GW_021 = 'RM-GW-021',
  RM_GW_022 = 'RM-GW-022',
  RM_GW_023 = 'RM-GW-023',
  RM_GW_100 = 'RM-GW-100',
  RM_GW_101 = 'RM-GW-101',
  RM_GW_199 = 'RM-GW-199'
}

/**
 *
 * @returns getGatewayStatus payload
 */
export const useGetGatewayStatus = () => {
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();

  const getGatewayStatus = async (macAddress: string) => {
    const payload = {
      action: 'checkConnection',
      payload: {
        mac_addr: macAddress
      }
    };
    const response = await handleGatewayCommand(payload).unwrap();
    return response;
  };

  return getGatewayStatus;
};

export const useUnregisterGateway = () => {
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();

  const unregisterGateway = async (gatewayInfo) => {
    // Send command to gateway via IoT
    const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.UNREGISTER, gatewayInfo);
    if (ioTPayload === 'Missing information') {
      throw new Error('Missing information');
    }
    await handleGatewayCommand(ioTPayload).unwrap();

    // Create a timeout for 60 seconds
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // Fetch the result from the gateway
    const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.UNREGISTER, gatewayInfo, null, null);
    const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

    return fetchResponse;
  };

  return unregisterGateway;
};

/**
 * Checks the IoT connection status of a gateway.
 *
 * @param macAddress - The MAC address of the gateway.
 * @param awsPropertyId - The AWS property ID associated with the gateway.
 * @param getGatewayStatus - A function that retrieves the status of the gateway based on its MAC address.
 * @returns An object containing the subscribed site ID and a boolean indicating if the gateway is ready to register.
 * @throws {GatewayError} Throws an error if the gateway is not connected, already registered to another site, or if there is no payload found.
 */
export const checkIoTConnection = async (
  macAddress: string,
  awsPropertyId: string,
  getGatewayStatus: (macAddress: string) => Promise<any>
) => {
  const inQueue = 'gw/queue';

  try {
    const gwStatus = await getGatewayStatus(macAddress);
    const parseSiteId = gwStatus?.topic.split('/')[1];

    if (gwStatus.topic === inQueue || awsPropertyId === parseSiteId) {
      return {
        subscribedSite: parseSiteId,
        readyToRegister: true
      };
    }

    if (!gwStatus.gateway_is_connected) {
      const disconnectReason = gwStatus.disconnect_reason;
      const errorCode =
        disconnectReason === 'CONNECTION_LOST' || disconnectReason === 'MQTT_KEEP_ALIVE_TIMEOUT'
          ? GatewayErrorCodes.RM_GW_002
          : GatewayErrorCodes.RM_GW_199;
      throw new GatewayError(errorCode, disconnectReason, parseSiteId);
    }

    if (parseSiteId !== awsPropertyId) {
      throw new GatewayError(GatewayErrorCodes.RM_GW_023, 'Gateway already registered to another site', parseSiteId);
    }
  } catch (error: any) {
    /** No matching MAC address found in the database */
    if (error.data?.errorCode === 'NO_PAYLOAD_FOUND') {
      throw new GatewayError(GatewayErrorCodes.RM_GW_001, 'NO_PAYLOAD_FOUND');
    }

    throw new GatewayError(error.code, error.message, error.siteId);
  }
};

export const getSerialNumber = (macAddress: string) => {
  const FNV_PRIME_64 = BigInt('0x100000001b3');
  const FNV_OFFSET_BASIS_64 = BigInt('14695981039346656037');

  let hash = FNV_OFFSET_BASIS_64;

  for (let i = 0; i < macAddress.length; i++) {
    hash ^= BigInt(macAddress.charCodeAt(i));
    hash = (hash * FNV_PRIME_64) % BigInt('0x10000000000000000'); // Ensure 32-bit overflow
  }

  return hash.toString(16); // Return hash as hexadecimal string
};
