import { Fragment, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { useQuikspecFeaturedSystems, useQuikSpecStandardSystems } from 'features/QuikSpec/Hooks/quikSpecDevices';

const NewSystem = () => {
  const [openDialogs, setOpenDialogs] = useState<{ [key: number]: boolean }>({});

  const FeaturedSystems = useQuikspecFeaturedSystems();
  const StandardSystems = useQuikSpecStandardSystems();
  const handleOpenDialog = (id: number) => {
    setOpenDialogs((prev) => ({
      ...prev,
      [id]: true
    }));
  };

  const handleCloseDialog = (id: number) => {
    setOpenDialogs((prev) => ({
      ...prev,
      [id]: false
    }));
  };

  const isIxixg = (seriesName: string) => seriesName === 'IX | IXG Series';
  const isAc = (seriesName: string) => seriesName === 'AC Series';
  const lgHorizontalLayout = (seriesName: string, isFeatured: boolean) => {
    if (isFeatured) {
      if (['JP Series', 'GT Series', 'JO Series', 'JV Series'].includes(seriesName)) {
        return 3;
      } else {
        return 4;
      }
    } else {
      return 2;
    }
  };
  const mdHorizontalLayout = (isFeatured: boolean) => {
    if (isFeatured) {
      return 6;
    } else {
      return 4;
    }
  };

  const showSystems = (systems: any[], isFeatured: boolean): any => {
    if (!systems) {
      // Protect when the content doesn't exist
      return null;
    }
    return (
      <Grid container spacing={4} sx={styles.container}>
        {systems.map((card, index) => (
          <Fragment key={index}>
            {isIxixg(card.seriesName) && (
              <Hidden lgDown>
                <Grid item lg={2} />
              </Hidden>
            )}
            <Grid
              item
              key={card.seriesName}
              xs={12}
              sm={12}
              md={mdHorizontalLayout(isFeatured)}
              lg={lgHorizontalLayout(card.seriesName, isFeatured)}
            >
              <Card raised sx={isFeatured ? styles.card : styles.stdCard}>
                <Link
                  to={`/quikspec/${card.siteName}`}
                  state={{ siteName: card.siteName }}
                  style={{ textDecoration: 'none' }}
                >
                  <CardMedia
                    component="img"
                    image={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/${card.seriesImageName}`}
                    alt={card.seriesName}
                    sx={isFeatured ? styles.cardMedia : styles.stdCardMedia}
                  />
                  <CardContent sx={isFeatured ? styles.cardContent : styles.stdCardContent}>
                    <Typography align="center" variant={isFeatured ? 'h4' : 'h5'} color="white.main" component={'span'}>
                      {card.seriesName}
                    </Typography>
                    <Typography align="center" variant={isFeatured ? 'h6' : 'h7'} color="white.main" component={'span'}>
                      {card.seriesDescription}
                    </Typography>
                  </CardContent>
                </Link>
                <CardActions sx={styles.cardActions}>
                  {isFeatured && (
                    <Box>
                      <IconButton onClick={() => handleOpenDialog(card.id)} sx={styles.infoIcon}>
                        <InfoOutlined color="primary"></InfoOutlined>
                      </IconButton>
                      <Dialog
                        open={openDialogs[card.id] || false}
                        onClose={() => handleCloseDialog(card.id)}
                        maxWidth="md"
                        fullWidth={true}
                        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } } }}
                      >
                        <DialogTitle align="center">{card.seriesSummary.title}</DialogTitle>
                        <Divider />
                        <DialogContentText align="center">
                          <List>
                            {card.seriesSummary.descriptions.map((desc: string, index: number) => (
                              <ListItem key={index}>
                                <ListItemText primary={'\u2022 ' + desc} />
                              </ListItem>
                            ))}
                          </List>
                        </DialogContentText>
                      </Dialog>
                    </Box>
                  )}
                </CardActions>
              </Card>
            </Grid>
            {isAc(card.seriesName) && (
              <Hidden lgDown>
                <Grid item lg={2} />
              </Hidden>
            )}
          </Fragment>
        ))}
      </Grid>
    );
  };

  return (
    <Grid container justifyContent="center">
      <Typography variant="h3" component={'span'}>
        Featured Systems
      </Typography>
      {showSystems(FeaturedSystems, true)}
      <Typography variant="h3" component={'span'}>
        Standard Systems
      </Typography>
      {showSystems(StandardSystems, false)}
    </Grid>
  );
};

const styles = {
  container: {
    paddingTop: 2,
    paddingBottom: 4,
    px: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    height: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  cardMedia: {
    height: 200,
    objectFit: 'contain'
  },
  cardContent: {
    py: 0.7,
    height: 80,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'primary.dark',
    color: 'white'
  },
  stdCard: {
    height: 190,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginX: { xs: 2, sm: 4, md: 0 }
  },
  stdCardMedia: {
    height: 130,
    objectFit: 'contain'
  },
  stdCardContent: {
    py: 0.5,
    height: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'primary.dark',
    color: 'white'
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  infoIcon: {
    top: -280,
    backgroundColor: 'white'
  }
};

export default NewSystem;
