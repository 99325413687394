import { Alert, AlertTitle, Box, List, ListItem, Typography } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import { accessories, doorStations, subStations, masterStations, lefHidden } from '../../../Types/LEFTypes';
import QuikspecCategoryTabs from '../../UiParts/QuikspecCategoryTabs';
import { updateFormValuesLEF } from 'features/QuikSpec/Utils/QuikspecUpdateFormValuesLEF';
import { Form, Formik } from 'formik';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';
import useQuikSpecFormValidationSchema from 'features/QuikSpec/Hooks/useQuikSpecFormValidationSchema';
import { useTranslation } from 'react-i18next';
import { useQuikSpecStandardSystems } from 'features/QuikSpec/Hooks/quikSpecDevices';

const QuikspecLEF = () => {
  const navigate = useNavigate();
  const StandardSystems = useQuikSpecStandardSystems();
  const location = useLocation();
  const { t } = useTranslation();
  const state = location.state || {};
  const siteName: string = location.state.siteName as string;
  const siteIndex = StandardSystems.findIndex((system) => system.siteName === siteName);
  const systemName = StandardSystems[siteIndex].seriesName;
  const systemforC2 = StandardSystems[siteIndex].systemforC2;

  const categories = [...masterStations, ...subStations, ...doorStations, ...accessories, ...lefHidden];
  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;
  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);
  const alertError = t('Error');

  const { lef } = useQuikSpecFormValidationSchema();

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      actions.setSubmitting(true);
      //filter out items with quantity set to zero in order to submit quikspec
      values = updateFormValuesLEF(values);

      //determine if form is in edit mode
      const editing = stateFormValues != undefined;

      if (editing) {
        //update QuikSpec and navigate to QuikSpec Summary page
        await updateQuikspecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
        return;
      } else {
        //create QuikSpec and navigate to QuikSpec Summary page
        await createQuikSpecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
      }
    } catch (error) {
      console.error(JSON.stringify(error));
      actions.setSubmitting(false);
      actions.setErrors({ submit: error.message });
    }
  };

  return (
    <Box>
      <Formik initialValues={initialValues} validationSchema={lef} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              {/* Render QuikspecCategoryTabs for each category */}
              <QuikspecCategoryTabs categories={masterStations} />
              <QuikspecCategoryTabs categories={subStations} />
              <QuikspecCategoryTabs categories={doorStations} />
              <QuikspecCategoryTabs categories={accessories} />
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>{alertError}</AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton editing={state.formValues != undefined} errors={errors} submitting={isSubmitting} />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecLEF;
