import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';

// Hidden products are designed not to be displayed.
export const ixgHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [
      {
        deviceName: 'SBX-IXDV30'
      },
      {
        deviceName: 'IX-MB'
      },
      {
        deviceName: 'SBX-IDVF'
      },
      {
        deviceName: 'SBX-IDVFRA'
      },
      {
        deviceName: 'SBX-IXGDM7'
      },
      {
        deviceName: 'SBX-2G/A'
      },
      {
        deviceName: 'SBX-NVP/A'
      },
      {
        deviceName: 'PS-2420UL'
      },
      {
        deviceName: 'IXG-DM7-BOX'
      },
      {
        deviceName: 'IXG-FMK-2C7'
      },
      {
        deviceName: 'TW-MKL'
      }
    ]
  }
];
