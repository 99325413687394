import { Box } from '@mui/material';
import UnitsControlPanel from './components/UnitsControlPanel';
import UnitsDataGrid from './components/UnitsDataGrid';
import AddUnitDialog from './components/AddUnitDialog';
import { useState } from 'react';
import { useGetUnitListWithSitePublicIdQuery } from 'services/aiphoneCloud';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useParams } from 'react-router-dom';

const Units = () => {
  const [isOpen, setIsOpen] = useState(false);
  const sitePublicId = useParams().id;
  const { isFetching, refetch } = useGetUnitListWithSitePublicIdQuery({
    sitePublicId: sitePublicId ?? '',
    qty: 500,
    page: 0
  });
  const { isAllowedTo } = usePermission();
  const canUnitView = isAllowedTo('unit:view', sitePublicId, PermissionsContextType.SITE);

  return (
    <>
      {canUnitView ? (
        <>
          <Box sx={styles.unitsWrapper}>
            <Box sx={styles.controlPanelWrapper}>
              <UnitsControlPanel setIsOpen={setIsOpen} refetchUnits={refetch} />
            </Box>
            <Box sx={styles.unitsDataGridWrapper}>
              <UnitsDataGrid isFetching={isFetching} />
            </Box>
          </Box>
          <AddUnitDialog isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
      ) : (
        <div>You do not have permission to view this content.</div>
      )}
    </>
  );
};

const styles = {
  unitsWrapper: {
    width: '100%',
    minWidth: '1250px',
    height: '100%'
  },
  unitsDataGridWrapper: {
    display: 'flex',
    height: '90%',
    marginY: '60px'
  },
  controlPanelWrapper: {
    width: '100%',
    height: '10%'
  }
};

export default Units;
