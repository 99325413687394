import React from 'react';
import { Grid, TextField, IconButton, InputAdornment, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import useQuantityChange from 'features/QuikSpec/Hooks/useQuantityChange';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '8px 12px',
    fontSize: '16px',
    width: '50px',
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&::placeholder': {
      color: '#333',
      opacity: 1
    }
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#888'
    },
    '&:hover fieldset': {
      borderColor: '#555'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#1976d2'
    }
  }
});

const StyledIconButton = styled(IconButton)({
  padding: '4px',
  margin: '0 5px',
  backgroundColor: '#f0f0f0',
  '&:hover': {
    backgroundColor: '#e0e0e0'
  },
  '& svg': {
    fontSize: '1.2rem'
  }
});

interface QuantityInputFormProps {
  itemName: string;
  multipleAllowed: boolean;
  maxQuantityPerItem?: number;
  accessories?: Array<{ accessoryName: string; deviceName?: string }>;
  /**
   * Indicates whether the item is currently selected.
   *
   * This component serves as a common quantity input component.
   * However, when used in JO/JV, it is part of a radio button group.
   * In such cases, if the item is not selected, the input should be disabled.
   *
   * As described above, this parameter (`isSelected`) is defined only in JO/JV.
   */
  isSelected?: boolean;
  isReadOnly?: boolean;
}

const QuantityInputForm: React.FC<QuantityInputFormProps> = ({
  itemName,
  multipleAllowed,
  maxQuantityPerItem = 9999,
  accessories,
  isSelected,
  isReadOnly
}) => {
  const { findFieldProps, handleQuantityChange, incrementQuantity, decrementQuantity } = useQuantityChange(
    itemName,
    multipleAllowed,
    maxQuantityPerItem
  );

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '0') {
      event.target.value = ''; // Clear the input if it's '0' when focused
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>, accessoryName?: string) => {
    if (event.target.value === '') {
      handleQuantityChange('0', accessoryName); // Set to '0' if the input is empty on blur
    } else {
      handleQuantityChange(event.target.value, accessoryName); // Ensure correct value is set on blur
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      {accessories &&
        accessories.map((accessory) => (
          <Grid item key={accessory.accessoryName}>
            <StyledTextField
              label={`${accessory.accessoryName}`}
              type="text" // Change to text to manage the custom behavior
              {...findFieldProps(accessory.accessoryName)}
              onFocus={handleFocus}
              onBlur={(event) => handleBlur(event as React.FocusEvent<HTMLInputElement>, accessory.accessoryName)}
              onChange={(event) => handleQuantityChange(event.target.value, accessory.accessoryName)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledIconButton
                      onClick={() => decrementQuantity(accessory.accessoryName)}
                      size="small"
                      disabled={isSelected === false || isReadOnly}
                    >
                      <RemoveIcon />
                    </StyledIconButton>
                    <StyledIconButton
                      onClick={() => incrementQuantity(accessory.accessoryName)}
                      size="small"
                      disabled={isSelected === false || isReadOnly}
                    >
                      <AddIcon />
                    </StyledIconButton>
                  </InputAdornment>
                ),
                inputProps: {
                  min: 0,
                  max: maxQuantityPerItem,
                  style: { textAlign: 'center' }
                }
              }}
              variant="outlined"
              fullWidth
              disabled={isSelected === false || isReadOnly}
            />
          </Grid>
        ))}
      {!accessories && (
        <Grid item>
          <StyledTextField
            label="Quantity"
            type="text" // Change to text to manage the custom behavior
            {...findFieldProps()}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(event) => handleQuantityChange(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <StyledIconButton onClick={() => decrementQuantity()} size="small" disabled={isReadOnly}>
                    <RemoveIcon />
                  </StyledIconButton>
                  <StyledIconButton onClick={() => incrementQuantity()} size="small" disabled={isReadOnly}>
                    <AddIcon />
                  </StyledIconButton>
                </InputAdornment>
              ),
              inputProps: {
                min: 0,
                max: maxQuantityPerItem,
                style: { textAlign: 'center' }
              }
            }}
            variant="outlined"
            fullWidth
            disabled={isSelected === false || isReadOnly}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default QuantityInputForm;
