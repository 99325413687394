import { Alert, AlertTitle, Box, List, ListItem, Typography } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import {
  jvMasterStations,
  jvDoorStations,
  optionalFeatures,
  jvSystemConstraints,
  jvHidden
} from '../../../Types/JVTypes';
import QuikspecCategoryTabs from '../../UiParts/QuikspecCategoryTabs';
import QuikspecCategoryTabsRadioButtons from '../../UiParts/QuikspecCategoryTabsRadioButtons';
import { updateFormValuesJV } from 'features/QuikSpec/Utils/QuikspecUpdateFormValuesJV';
import { Form, Formik } from 'formik';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import QuikspecSystemConstraints from '../../UiParts/QuikspecSystemConstraints';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';
import useQuikSpecFormValidationSchema from 'features/QuikSpec/Hooks/useQuikSpecFormValidationSchema';
import { useTranslation } from 'react-i18next';
import { useQuikspecFeaturedSystems } from 'features/QuikSpec/Hooks/quikSpecDevices';

const QuikspecJV = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const FeaturedSystems = useQuikspecFeaturedSystems();
  const state = location.state || {};
  const siteName: string = location.state.siteName as string;
  const siteIndex = FeaturedSystems.findIndex((system) => system.siteName === siteName);
  const systemName = FeaturedSystems[siteIndex].seriesName;
  const systemforC2 = FeaturedSystems[siteIndex].systemforC2;
  const categories = [...jvMasterStations, ...jvDoorStations, ...optionalFeatures, ...jvHidden];
  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;
  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);
  const alertError = t('Error');
  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      actions.setSubmitting(true);
      //filter out items with quantity set to zero in order to submit quikspec
      values = updateFormValuesJV(values);
      //determine if form is in edit mode
      const editing = stateFormValues != undefined;
      if (editing) {
        //update QuikSpec and navigate to QuikSpec Summary page
        await updateQuikspecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
        return;
      } else {
        //create QuikSpec and navigate to QuikSpec Summary page
        await createQuikSpecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
      }
    } catch (_) {
      actions.setSubmitting(false);
      actions.setErrors({ submit: t('Something_went_wrong_try_again') });
    }
  };

  const { jv } = useQuikSpecFormValidationSchema();

  return (
    <Box>
      <Formik initialValues={initialValues} validationSchema={jv} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              <QuikspecSystemConstraints systemConstraints={jvSystemConstraints} />
              {/* Render QuikspecCategoryTabs for each category */}
              <QuikspecCategoryTabs categories={jvMasterStations} />
              <QuikspecCategoryTabsRadioButtons categories={jvDoorStations} initialValues={initialValues} />
              <QuikspecCategoryTabs categories={optionalFeatures} />
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>{alertError}</AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton editing={state.formValues != undefined} errors={errors} submitting={isSubmitting} />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecJV;
