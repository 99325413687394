import { Card, CardContent, Grid, List, Typography, ListItem } from '@mui/material';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import { useTranslation } from 'react-i18next';

interface ISiteRoleDescriptionsProps {
  roleName: string;
}

interface IDescription {
  [key: string]: {
    name: string;
    permissions: string[];
  };
}

const SiteRoleDescriptions = ({ roleName }: ISiteRoleDescriptionsProps) => {
  const { t } = useTranslation();

  // Function to format the role name
  const formatRoleName = (name: string) => {
    return name.replace(/([A-Z])/g, ' $1').trim();
  };

  const description: IDescription = {
    Admin: {
      name: t('Admin'),
      permissions: [
        t('Manage_Site_Administrators_and_Settings'),
        t('Manage_Devices_and_Apps'),
        t('Manage_Units_and_Tenants')
      ]
    },
    Manager: {
      name: t('Manager'),
      permissions: [
        t('Manage_Site_Administrators_and_Settings'),
        t('Manage_Devices_and_Apps'),
        t('Manage_Units_and_Tenants')
      ]
    },
    Member: {
      name: t('Member'),
      permissions: [t('View_Site_Administrators_and_Settings'), t('View_Devices_and_Apps'), t('View_Units_and_Tenants')]
    },
    AiphoneAdmin: {
      name: t('Aiphone_Admin'),
      permissions: [t('Invite_User'), t('Manage_Site'), t('Delete_Site')]
    },
    AiphoneManager: {
      name: t('Aiphone_Manager'),
      permissions: [t('Invite_Non_Admin_Users'), t('Manage_Site')]
    },
    AiphoneMember: {
      name: t('Aiphone_Member'),
      permissions: [t('View_Site_Setting')]
    }
  };

  return (
    <Grid item xs={12}>
      {roleName && description[roleName] && (
        <Card sx={styles.permissionContainer}>
          <Typography variant="h6" color="initial">
            {t('Permissions_granted_to')} {formatRoleName(roleName)}:
          </Typography>
          <CardContent>
            <List>
              {description[roleName].permissions.map((desc, index) => (
                <ListItem key={index}>
                  <PersonPinCircleOutlinedIcon />
                  <Typography variant="body2" color="initial" sx={{ paddingX: 2 }}>
                    {desc}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};

const styles = {
  permissionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 1,
    paddingX: 1
  }
};

export default SiteRoleDescriptions;
