import { Box, Container, Button, Alert } from '@mui/material';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useState } from 'react';
import DeleteSiteDialog from './Dialogs/DeleteSiteDialog';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { IGatewayInfo, IDeviceInfo } from './Dialogs/DeleteSiteDialog';

const SiteSettings = () => {
  const { t } = useTranslation();
  const publicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const gatewayPublicId = useSelector((state: RootState) => state.site.siteInfo.registeredGatewayPublicId);
  const awsPropertyId = useSelector((state: RootState) => state.site.siteInfo.awsPropertyId);
  const gatewayDevice = useSelector((state: RootState) => state.devices.DeviceList[gatewayPublicId]);

  const { isAllowedTo } = usePermission();
  const canDeleteSite = isAllowedTo('site:delete', publicId, PermissionsContextType.SITE);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [gatewayInfo, setGatewayInfo] = useState<IGatewayInfo>({
    awsPropertyId: '',
    gwMacAddress: '',
    gwId: '',
    gwPassword: '',
    gwIpAddress: ''
  });
  const [deviceInfo, setDeviceInfo] = useState<IDeviceInfo>({
    deviceMacAddress: '',
    deviceIpAddress: '',
    deviceType: 18,
    deviceId: '',
    devicePassword: ''
  });

  useEffect(() => {
    if (!gatewayPublicId) return;

    /** If gateway is not synced (null value), then use default id & pw */

    const isGwFirstSynced = gatewayDevice?.lastSyncedOn;
    setGatewayInfo({
      awsPropertyId: awsPropertyId,
      gwMacAddress: gatewayDevice?.basicInfo.macAddress,
      gwId: isGwFirstSynced ? gatewayDevice?.basicInfo.adminId : 'admin',
      gwPassword: isGwFirstSynced ? gatewayDevice?.basicInfo.adminPass : 'admin',
      gwIpAddress: gatewayDevice?.networkSettings?.ipV4Address || ''
    });

    setDeviceInfo({
      deviceMacAddress: gatewayDevice?.basicInfo.macAddress,
      deviceIpAddress: gatewayDevice?.networkSettings?.ipV4Address || '',
      deviceType: gatewayDevice?.basicInfo.deviceType,
      deviceId: isGwFirstSynced ? gatewayDevice?.basicInfo.adminId : 'admin',
      devicePassword: isGwFirstSynced ? gatewayDevice?.basicInfo.adminPass : 'admin'
    });
  }, [gatewayPublicId]);

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" color="text.primary" sx={{ paddingBottom: '5px' }}>
        {t('Site_Settings')}
      </Typography>

      <Box sx={styles.gwContainer}>
        <Box sx={styles.buttonGWContainer}>
          <Button variant="contained" onClick={() => setIsDeleteDialogOpen(true)} disabled={!canDeleteSite}>
            {t('Delete_Site')}
          </Button>
          <Alert severity="warning">{t('Warning_Delete_Site')}</Alert>
        </Box>
      </Box>
      {/* Delete confirmation dialog */}
      <DeleteSiteDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        publicId={publicId}
        gatewayInfo={gatewayInfo}
        deviceInfo={deviceInfo}
      />
    </Container>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  buttonGWContainer: {
    display: 'flex',
    justifyContent: 'end',
    padding: '1rem'
  },
  gwContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '1rem'
  }
};

export default SiteSettings;
