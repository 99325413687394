import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDeleteSiteMutation, useHandleGatewayCommandMutation } from 'services/aiphoneCloud';
import { useTranslation } from 'react-i18next';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';

export interface IGatewayInfo {
  awsPropertyId: string;
  gwMacAddress: string;
  gwId: string;
  gwPassword: string;
  gwIpAddress: string;
}
export interface IDeviceInfo {
  deviceMacAddress: string;
  deviceIpAddress: string;
  deviceType: number;
  deviceId: string;
  devicePassword: string;
}
interface DeleteSiteDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  publicId: string;
  gatewayInfo: IGatewayInfo;
  deviceInfo: IDeviceInfo;
}

const DeleteSiteDialog: React.FC<DeleteSiteDialogProps> = ({
  isOpen,
  setIsOpen,
  publicId,
  gatewayInfo,
  deviceInfo
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const [deleteSite] = useDeleteSiteMutation();
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();

  const handleClose = () => {
    setIsOpen(false);
  };

  /**
   *  Implement the delete site functionality
   *  [TODO]: Initialize all the devices added to the site
   *  [Implemented]: Initialize the gateway device
   */

  const handleDeleteSite = async () => {
    setLoading(true);
    setErrorMessage(null);

    /** Send Initialize the Gateway command  */
    try {
      // send command to gateway via ioT
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.INITIALIZE, gatewayInfo, deviceInfo, null);
      if (ioTPayload === undefined) throw new Error('IoT Payload undefined');

      await handleGatewayCommand(ioTPayload).unwrap();
      // create a timeout for 30 seconds
      await new Promise((resolve) => setTimeout(resolve, 30000));

      // fetch the result from the gateway
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.INITIALIZE, gatewayInfo, deviceInfo, null);
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
      const statusCode = fetchResponse?.statusCode.slice(0, 3);
      if (statusCode !== '200') {
        setErrorMessage(t('Unable_to_initialize_device', { statusCode }));
      }
    } catch (error) {
      /** Current firmware does not send success response
       *  after initialization complete (ONLY FOR GATEWAY) and
       *  it will reboot the gateway after initialization.
       *  So, we will not get any response from the gateway to fetch in db
       *  so even though we have error, we can proceed to delete the site
       * */
      console.error(error);
    }

    try {
      await deleteSite(publicId).unwrap();
      setLoading(false);
      setIsOpen(false);
      navigate('/remotemanagement');
    } catch (error) {
      setErrorMessage(t('Failed_to_delete_site_Please_try_again'));
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('Permanently_Delete_Site')}</DialogTitle>
      <DialogContent>
        <Typography>
          {t('Are_you_sure_you_want_to_permanently_delete_this_site_This_action_cannot_be_undone')}
        </Typography>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" disabled={loading}>
          {t('Cancel')}
        </Button>
        <Button
          onClick={handleDeleteSite}
          variant="contained"
          color="error"
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {t('Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSiteDialog;
