import React, { useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './customStyles.css';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const localizer = momentLocalizer(moment);

const AppCalendar = () => {
  const [events, setEvents] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: '',
    start: null,
    end: null,
    description: '',
    scheduleType: 'single',
    recurrence: 'none',
  });
  const { t } = useTranslation();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const pastBackgroundColor = '#e0e0e0'; // Lighter gray to indicate past dates

  const WEEK_VIEW = Views.WEEK;

  const useCalendarView = (initialView = WEEK_VIEW) => {
    const [view, setView] = useState(initialView);
    return [view, setView];
  };

  const [_view, setView] = useCalendarView();

  // Function to gray out past days and prevent them from being selectable
  const dayPropGetter = (date) => {
    if (moment(date).isBefore(moment(), 'day')) {
      return {
        style: {
          backgroundColor: pastBackgroundColor,
          pointerEvents: 'none', // Prevent selection
        },
      };
    }
    return {
      style: {},
    };
  };

  const generateRecurringEvents = (event) => {
    let recurringEvents = [];
    const { recurrence, start, end } = event;
    let currentStart = moment(start);
    let currentEnd = moment(end);

    if (recurrence === 'daily') {
      for (let i = 0; i < 30; i++) {
        recurringEvents.push({
          ...event,
          id: `${event.id}-${i}`,
          start: currentStart.toDate(),
          end: currentEnd.toDate(),
        });
        currentStart = currentStart.add(1, 'day');
        currentEnd = currentEnd.add(1, 'day');
      }
    } else if (recurrence === 'weekly') {
      for (let i = 0; i < 10; i++) {
        recurringEvents.push({
          ...event,
          id: `${event.id}-${i}`,
          start: currentStart.toDate(),
          end: currentEnd.toDate(),
        });
        currentStart = currentStart.add(1, 'week');
        currentEnd = currentEnd.add(1, 'week');
      }
    } else if (recurrence === 'monthly') {
      for (let i = 0; i < 12; i++) {
        recurringEvents.push({
          ...event,
          id: `${event.id}-${i}`,
          start: currentStart.toDate(),
          end: currentEnd.toDate(),
        });
        currentStart = currentStart.add(1, 'month');
        currentEnd = currentEnd.add(1, 'month');
      }
    }
    return recurringEvents;
  };

  // Handle slot selection for adding new events
  const handleSelectSlot = ({ start, end }) => {
    if (moment(start).isBefore(moment(), 'day')) {
      return; // Prevent adding events to past dates
    }
    setSelectedEvent(null); // Clear selected event when creating a new event
    setNewEvent({
      title: '',
      start,
      end,
      description: '',
      scheduleType: 'single',
      recurrence: 'none',
    });
    setIsDialogOpen(true);
  };

  const handleAddEvent = () => {
    if (newEvent.title.trim() === '' || !newEvent.start || !newEvent.end) {
      toast.error('Please fill out all required fields.');
      return;
    }

    if (newEvent.end <= newEvent.start) {
      toast.error('End time must be after start time.');
      return;
    }

    let newEvents = [
      {
        id: events.length + 1,
        title: `${newEvent.title}`,
        start: newEvent.start,
        end: newEvent.end,
        description: newEvent.description,
        scheduleType: newEvent.scheduleType,
        recurrence: newEvent.recurrence,
      },
    ];

    // If the event is recurring, generate the recurring events
    if (newEvent.scheduleType === 'recurring' && newEvent.recurrence !== 'none') {
      newEvents = generateRecurringEvents(newEvents[0]);
    }

    setEvents((prevEvents) => [...prevEvents, ...newEvents]);
    setIsDialogOpen(false);
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setNewEvent({
      title: event.title,
      start: event.start,
      end: event.end,
      description: event.description,
      scheduleType: event.scheduleType,
      recurrence: event.recurrence,
    });
    setIsDialogOpen(true);
  };

  const handleUpdateEvent = () => {
    if (newEvent.title.trim() === '' || !newEvent.start || !newEvent.end) {
      toast.error('Please fill out all required fields.');
      return;
    }

    if (newEvent.end <= newEvent.start) {
      toast.error('End time must be after start time.');
      return;
    }

    // Remove old recurring events
    const baseId = String(selectedEvent.id).split('-')[0]; // Ensure `id` is treated as a string
    const filteredEvents = events.filter((event) => String(event.id).split('-')[0] !== baseId);

    let updatedEvents = [
      {
        id: baseId,
        title: newEvent.title,
        start: newEvent.start,
        end: newEvent.end,
        description: newEvent.description,
        scheduleType: newEvent.scheduleType,
        recurrence: newEvent.recurrence,
      },
    ];

    // Generate updated recurring events if recurrence is set
    if (newEvent.scheduleType === 'recurring' && newEvent.recurrence !== 'none') {
      updatedEvents = generateRecurringEvents(updatedEvents[0]);
    }

    setEvents([...filteredEvents, ...updatedEvents]);
    setSelectedEvent(null); // Clear selected event after updating
    setIsDialogOpen(false);
  };

  const handleDeleteEvent = () => {
    if (selectedEvent) {
      const baseId = String(selectedEvent.id).split('-')[0]; // Ensure `id` is treated as a string
      setEvents((prevEvents) => prevEvents.filter((e) => String(e.id).split('-')[0] !== baseId));
    }
    setIsDialogOpen(false);
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.onNavigate('PREV');
    };
    const goToNext = () => {
      toolbar.onNavigate('NEXT');
    };
    const changeView = (newView) => {
      toolbar.onView(newView);
      setView(newView);
    };
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, p: 1, backgroundColor: '#fff', borderRadius: 2, boxShadow: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={goToBack} sx={{ ml: 2 }}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mx: 2, textAlign: 'center' }}>
            {toolbar.label}
          </Typography>
          <IconButton onClick={goToNext} sx={{ mr: 2 }}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
          <Button onClick={() => changeView(Views.MONTH)} variant="outlined" sx={{ mr: 1 }}>
            {t('NEXT-IX.calendar.views.monthly')}
          </Button>
          <Button onClick={() => changeView(Views.WEEK)} variant="outlined" sx={{ mr: 1 }}>
            {t('NEXT-IX.calendar.views.weekly')}
          </Button>
          <Button onClick={() => changeView(Views.DAY)} variant="outlined" sx={{ mr: 1 }}>
            {t('NEXT-IX.calendar.views.today')}
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ height: '80vh', p: 3, borderRadius: 3, boxShadow: 3 }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        showMultiDayTimes={true}
        style={{ height: '100%', borderRadius: '12px' }}
        dayPropGetter={dayPropGetter}
        components={{
          toolbar: CustomToolbar,
        }}
        defaultView={Views.WEEK}
        views={[Views.MONTH, Views.WEEK, Views.DAY]}
        onView={(newView) => setView(newView)}
      />

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {selectedEvent ? t('NEXT-IX.calendar.updateEvent') : t('NEXT-IX.calendar.addEvent')}
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" sx={{ mb: 3 }}>
            <RadioGroup
              row
              value={newEvent.scheduleType}
              onChange={(e) => setNewEvent({ ...newEvent, scheduleType: e.target.value })}
            >
              <FormControlLabel value="single" control={<Radio />} label={t('NEXT-IX.calendar.scheduleType.single')} />
              <FormControlLabel value="recurring" control={<Radio />} label={t('NEXT-IX.calendar.scheduleType.recurring')} />
            </RadioGroup>
          </FormControl>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              autoFocus
              margin="dense"
              label={t('NEXT-IX.calendar.title')}
              fullWidth
              value={newEvent.title}
              onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
            />
            <TextField
              margin="dense"
              label={t('NEXT-IX.calendar.startTime')}
              type="datetime-local"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={newEvent.start ? moment(newEvent.start).format('YYYY-MM-DDTHH:mm') : ''}
              onChange={(e) => setNewEvent({ ...newEvent, start: new Date(e.target.value) })}
            />
            <TextField
              margin="dense"
              label={t('NEXT-IX.calendar.endTime')}
              type="datetime-local"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={newEvent.end ? moment(newEvent.end).format('YYYY-MM-DDTHH:mm') : ''}
              onChange={(e) => setNewEvent({ ...newEvent, end: new Date(e.target.value) })}
            />
            <TextField
              margin="dense"
              label={t('NEXT-IX.calendar.description')}
              fullWidth
              multiline
              rows={4}
              value={newEvent.description}
              onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
            />

            {newEvent.scheduleType === 'recurring' && (
              <FormControl fullWidth>
                <InputLabel id="Recurrence-label">{t('NEXT-IX.calendar.recurrenceLabel')}</InputLabel>
                <Select
                  value={newEvent.recurrence}
                  labelId="Recurrence-label"
                  onChange={(e) => setNewEvent({ ...newEvent, recurrence: e.target.value })}
                  label="Recurrence"
                >
                  <MenuItem value="none">{t('NEXT-IX.calendar.recurrence.none')}</MenuItem>
                  <MenuItem value="daily">{t('NEXT-IX.calendar.recurrence.daily')}</MenuItem>
                  <MenuItem value="weekly">{t('NEXT-IX.calendar.recurrence.weekly')}</MenuItem>
                  <MenuItem value="monthly">{t('NEXT-IX.calendar.recurrence.monthly')}</MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {selectedEvent && (
            <Button onClick={handleDeleteEvent} color="error">
              {t('NEXT-IX.calendar.deleteEvent')}
            </Button>
          )}
          <Button onClick={() => setIsDialogOpen(false)} color="secondary">
            {t('NEXT-IX.calendar.cancel')}
          </Button>
          <Button onClick={selectedEvent ? handleUpdateEvent : handleAddEvent} color="primary">
            {selectedEvent ? t('NEXT-IX.calendar.update') : t('NEXT-IX.calendar.addEvent')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AppCalendar;
