import { Box, Grid, Input, Slider, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { IContactOutput, IDeviceContactInput } from 'store/slices/devicesSlice';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTranslation } from 'react-i18next';

interface IOutputTimeSettingProps {
  selectedRelay: IContactOutput;
  setNeedsSave: (needsSave: boolean) => void;
  newRelaySettings: Array<IContactOutput | IDeviceContactInput>;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<IContactOutput | IDeviceContactInput>>>;
  outputFunction: number;
}

const OutputTimeSetting = ({
  selectedRelay,
  setNeedsSave,
  newRelaySettings,
  setNewRelaySettings,
  outputFunction
}: IOutputTimeSettingProps) => {
  const { t } = useTranslation();
  const milliseconds = t('Milliseconds_Text');
  const seconds = t('Seconds_Text');
  const [value, setValue] = useState<number>(selectedRelay.timer);
  const [useSeconds, setUseSeconds] = useState<boolean>(true);

  useEffect(() => {
    setValue(selectedRelay.timer);
  }, [selectedRelay]);

  useEffect(() => {
    // Find the relay in newRelaySettings that matches the selectedRelay's publicId
    const matchingRelay = newRelaySettings.find((relay) => relay.publicId === selectedRelay.publicId);
    if (matchingRelay && 'outputName' in matchingRelay && matchingRelay.timer !== selectedRelay.timer) {
      // If a matching relay is found and its timer is different from the selectedRelay's timer,
      // update the timer state with the new timer from newRelaySettings
      setValue(matchingRelay.timer);
    } else {
      // If no matching relay is found or the names are the same, use the selectedRelay's timer
      setValue(selectedRelay.timer);
    }
  }, [newRelaySettings, selectedRelay]);

  const handleNewRelaySettings = (timer: number) => {
    if (timer !== selectedRelay.timer) {
      setNeedsSave(true);
      setNewRelaySettings((prev) => {
        const newSettingsArray = prev;
        const currentRelaySettings = newSettingsArray.find((relay) => relay.publicId === selectedRelay.publicId);

        const index = newSettingsArray.findIndex((relay) => relay.publicId === selectedRelay.publicId);
        if (index !== -1) {
          if (currentRelaySettings) newSettingsArray[index] = { ...currentRelaySettings, timer };
        } else {
          newSettingsArray.push({ ...selectedRelay, timer });
        }
        return newSettingsArray;
      });
    } else {
      setNeedsSave(false);
      setNewRelaySettings((prev) => {
        return prev.filter((relay) => relay.publicId !== selectedRelay.publicId);
      });
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
    handleNewRelaySettings(newValue as number);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value) * (useSeconds ? 1000 : 1));
    handleNewRelaySettings(Number(event.target.value) * (useSeconds ? 1000 : 1));
  };

  const handleBlur = () => {
    let newValue = value;
    // Adjust the validation based on the unit
    if (useSeconds) {
      if (newValue < 3000) {
        // 3 seconds in milliseconds
        newValue = 3000;
      } else if (newValue > 300000) {
        // 300 seconds in milliseconds
        newValue = 300000;
      }
    } else {
      if (newValue < 200) {
        newValue = 200;
      } else if (newValue > 2000) {
        newValue = 2000;
      }
      newValue = Math.round(Number(newValue) / 200) * 200;
    }
    setValue(newValue);
  };

  const toggleSeconds = (isSeconds: boolean) => {
    setUseSeconds(isSeconds);
    // Ensure conversion respects the new ranges
    let newValue: number;
    if (isSeconds) {
      // If switching to seconds
      newValue = Math.max(3000, Math.min(value, 300000)) / 1000; // Convert and clamp within 3-300
    } else {
      // If switching to milliseconds
      newValue = Math.max(200, Math.min(value * 1000, 2000)); // Convert and clamp within 200-2000
    }
    setValue(newValue);
  };

  if (outputFunction != 4) {
    return null;
  }

  return (
    <Box sx={styles.settingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{t('Output_Time')}</Box>
        <Box sx={styles.description}> {t('Output_Time_Desc')}</Box>
      </Box>
      <Box sx={styles.sliderWrapper}>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item>
            <AccessTimeIcon />
          </Grid>
          <Grid item xs>
            <Slider
              value={useSeconds && value > 1000 ? value / 1000 : value} // Convert to seconds if using seconds
              onChange={handleSliderChange}
              min={useSeconds ? 3 : 200} // Adjusted min based on unit
              max={useSeconds ? 600 : 2000} // Adjusted max based on unit
              step={useSeconds ? 1 : 200}
            />
          </Grid>
          <Grid item>
            <Input
              value={useSeconds && value > 1000 ? value / 1000 : value}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: useSeconds ? 1 : 200,
                min: useSeconds ? 3 : 200, // Adjusted min based on unit
                max: useSeconds ? 600 : 2000, // Adjusted max based on unit
                type: 'number',
                'aria-labelledby': 'input-slider'
              }}
            />
          </Grid>
          <Grid item>
            <ToggleButtonGroup value={useSeconds} exclusive onChange={(_, value) => toggleSeconds(value)}>
              <ToggleButton value={false} aria-label="miliseconds" sx={styles.toggleButton}>
                <Typography>{milliseconds}</Typography>
              </ToggleButton>
              <ToggleButton value={true} aria-label="seconds" sx={styles.toggleButton}>
                <Typography>{seconds}</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  sliderWrapper: {
    width: '40%'
  },
  toggleButton: {
    textTransform: 'none'
  }
};

export default OutputTimeSetting;
