import { FormValues } from '../Types/QuikspecFormTypes';
import { updateFormValues } from './QuikspecUpdateFormValues';
import { updateItemQuantityWithAccessoriesAC } from './QuikspecUpdateFormValuesAC';

export const updateFormValuesJP = (values: FormValues) => {
  values = updateFormValues(values);
  updateItemQuantityWithAccessoriesJP(values);
  updateItemQuantityWithAccessoriesAC(values);
  updateWiresJP(values);
  updateNotesJP(values);
  return values;
};

const updateItemQuantityWithAccessoriesJP = (values: FormValues) => {
  const items = Object.values(values.items);

  // Add RY-1824L and RY-3DL depending on the number of doors
  const totalDoorStations = items
    .filter((item) => item.category === 'Door Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const RY1824LIndex = items.findIndex((item) => item.itemName === 'RY-1824L');
  if (totalDoorStations < 3) {
    items[RY1824LIndex].quantity = totalDoorStations;
  } else {
    const RY3DLIndex = items.findIndex((item) => item.itemName === 'RY-3DL');
    items[RY1824LIndex].quantity = 1;
    items[RY3DLIndex].quantity = 1;
  }

  // Kit determination
  const JP4MEDIndex = items.findIndex((item) => item.itemName === 'JP-4MED');
  const JPDAIndex = items.findIndex((item) => item.itemName === 'JP-DA');
  const JPDVIndex = items.findIndex((item) => item.itemName === 'JP-DV');
  const JPDVFIndex = items.findIndex((item) => item.itemName === 'JP-DVF');
  const PS2420ULIndex = items.findIndex((item) => item.itemName === 'PS-2420UL');
  if (items[JPDAIndex].quantity > 0) {
    const JPS4AEDIndex = items.findIndex((item) => item.itemName === 'JPS-4AED');
    items[JPS4AEDIndex].quantity = 1;
    items[JP4MEDIndex].quantity = 0;
    items[JPDAIndex].quantity -= 1;
  } else if (items[JPDVIndex].quantity > 0) {
    const JPS4AEDVIndex = items.findIndex((item) => item.itemName === 'JPS-4AEDV');
    items[JPS4AEDVIndex].quantity = 1;
    items[JP4MEDIndex].quantity = 0;
    items[JPDVIndex].quantity -= 1;
  } else if (items[JPDVFIndex].quantity > 0) {
    const JPS4AEDFIndex = items.findIndex((item) => item.itemName === 'JPS-4AEDF');
    items[JPS4AEDFIndex].quantity = 1;
    items[JP4MEDIndex].quantity = 0;
    items[JPDVFIndex].quantity -= 1;
  } else {
    items[PS2420ULIndex].quantity = 1;
  }

  // Distributor and Power Supply
  const totalSubMasterStations = items
    .filter((item) => item.itemName === 'JP-4HD')
    .reduce((total: number, item) => total + item.quantity, 0);
  const JP8ZIndex = items.findIndex((item) => item.itemName === 'JP-8Z');

  if (totalSubMasterStations > 0) {
    items[JP8ZIndex].quantity = 1;
    items[PS2420ULIndex].quantity += 1;
  }
  if (totalSubMasterStations > 4) {
    items[PS2420ULIndex].quantity += 1;
  }
};

const updateWiresJP = (values: FormValues) => {
  values.wires.push(
    {
      system: 'Door to Master Station',
      wire: '871802',
      description: "2 Cond., 18AWG, Solid, Non-shielded, Low-cap, Available in: 500' & 1000'",
      maxDistance: "330'",
      jacketNomOD: 'PVC .190"',
      capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
    },
    {
      system: 'Power to Master Station',
      wire: '871802',
      description: "2 Cond., 18AWG, Solid, Non-shielded, Low-cap, Available in: 500' & 1000'",
      maxDistance: "33'",
      jacketNomOD: 'PVC .190"',
      capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
    }
  );

  if (
    values.items
      .filter((item) => item.itemName === 'JP-4HD')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  )
    values.wires.push({
      system: 'Master Station to JP-8Z or JP-4HD',
      wire: 'CAT-5e/6',
      description: 'UTP4 non-shielded Category 5e/6 cable (Not Available from Aiphone)',
      maxDistance: 'Per Spec',
      jacketNomOD: 'Per Spec',
      capacitanceLoopRes: 'Per Spec'
    });
};

const updateNotesJP = (values: FormValues) => {
  if (
    values.items
      .filter((item) => item.itemName === 'RY-1824L')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'An RY-1824L has been added to the parts list for door release. The JP Series has two N/O dry contacts, rated at 24V AC/DC, 500 mA. If this specification meets your system requirements, then a separate relay is not required. If the door release hardware is rated at a higher voltage or current draw, or if you are using a magnetic lock, include the door release relay specified above.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'RY-ES')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The RY-ES provides a momentary contact upon activation. Any external device with its own power supply may be connected to this contact. (Not supplied by Aiphone.)'
    );
  }
};
