import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch
} from '@mui/material';
import { UserInfo } from 'shared/utils/UserUtils';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import ChangePasswordDialog from 'shared/components/ChangePasswordDialog';
import { useUpdateUserMutation } from 'services/aiphoneCloud';
import { CloudUser, getCurrentUser, IUserPermissions, setUserProfile } from 'store/slices/usersSlice';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { NANP_PHONE_REGEX } from 'shared/constants/regex';
import { JSX } from 'react/jsx-runtime';
import { convertPhoneNumberToE164Format, formatPhoneNumber } from 'shared/utils/helperFunctions';
import MFASettingsDialog from '../../../../shared/components/MFASettingsDialog';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  marketingOptIn: boolean;
}

const UserProfile = () => {
  const userObj = useSelector(getCurrentUser);
  const [userInfo, setUserInfo] = useState(new UserInfo(userObj));
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);
  const [isMFASettingsDialogOpen, setIsMFASettingsDialogOpen] = useState(false);
  const [updateUser] = useUpdateUserMutation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentUser = useSelector(getCurrentUser);
  const permissions: IUserPermissions | undefined = currentUser?.permissions;
  const branchRoles = permissions?.branch;
  const registeredUser = currentUser ? !permissions || (!permissions.global && !permissions.branch) : true;
  const hasGlobalRole =
    permissions && permissions.global?.roleList !== null && permissions.global?.roleList !== undefined;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('First_Name_Required')),
    lastName: Yup.string().required(t('Last_Name_Required')),
    email: Yup.string().email(t('Email_Invalid')).required(t('Email_Required')),
    phoneNumber: Yup.string().required(t('Phone_Number_Required')).matches(NANP_PHONE_REGEX, t('Invalid_phone_number'))
  });

  const handleUpdateUser = (values: FormValues) => {
    const formattedEmail = values.email.toLowerCase();
    const updateUserRequest = {
      userData: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: formattedEmail,
        phoneNumber: convertPhoneNumberToE164Format(values.phoneNumber),
        phoneExtension: userInfo.phoneExtension,
        countryId: userInfo.countryId,
        language: userInfo.language,
        marketingOptIn: values.marketingOptIn
      }
    };
    setIsLoading(true);
    setUserInfo(new UserInfo(values));
    updateUser(updateUserRequest)
      .unwrap()
      .then(() => {
        setSuccessMessage(t('User_Updated_Successfully'));
        setIsSuccess(true);
        setIsLoading(false);
        const updatedUser = new UserInfo(values);
        updatedUser.saveUser();

        // Store updated user information to user slice
        // This will keep the user slice up to date until reloading
        const newUserProfile: CloudUser = {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          marketingOptIn: values.marketingOptIn
        };
        dispatch(setUserProfile(newUserProfile));
      })
      .catch(() => {
        setErrorMessage(t('User_Update_Failed'));
        setIsError(true);
        setIsLoading(false);
      });
  };

  const generateBranchRoleRows = () => {
    const rows: JSX.Element[] = [];
    if (branchRoles) {
      Object.entries(branchRoles).forEach(([branchId, role]) => {
        rows.push(
          <TableRow key={branchId}>
            <TableCell>{role.companyName}</TableCell>
            <TableCell>{role.branchName}</TableCell>
            <TableCell>{role.roleList[0].roleName}</TableCell>
          </TableRow>
        );
      });
    }
    return rows;
  };

  const ViewUser = () => {
    return (
      <>
        <Box sx={styles.userContainer}>
          <Typography variant="h5" color="initial">
            {t('User_Profile')}
          </Typography>
          <Formik
            initialValues={
              {
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                email: userInfo.email,
                phoneNumber: userInfo.phoneNumber,
                marketingOptIn: userInfo.marketingOptIn
              } as FormValues
            }
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleUpdateUser(values);
            }}
            enableReinitialize
          >
            {({ errors, touched, values, handleChange, setFieldValue, dirty }) => {
              return (
                <Form>
                  <Box sx={styles.formContainer}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          label={t('First_Name')}
                          name="firstName"
                          size="small"
                          fullWidth={true}
                          helperText={touched.firstName && errors.firstName ? errors.firstName : ''}
                          error={touched.firstName && !!errors.firstName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          label={t('Last_Name')}
                          name="lastName"
                          size="small"
                          fullWidth={true}
                          helperText={touched.lastName && errors.lastName ? errors.lastName : ''}
                          error={touched.lastName && !!errors.lastName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field as={TextField} label="Email" name="email" size="small" fullWidth={true} disabled />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const { value } = e.target;
                            if (value === '' || value === '+') {
                              setFieldValue('phoneNumber', '+');
                            } else if (value.startsWith('+1') && value.replace(/\D/g, '').length <= 11) {
                              const formattedValue = formatPhoneNumber(value);
                              setFieldValue('phoneNumber', formattedValue);
                            }
                          }}
                          label={t('Phone_Number')}
                          name="phoneNumber"
                          size="small"
                          fullWidth={true}
                          helperText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''}
                          error={touched.phoneNumber && !!errors.phoneNumber}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" color="initial">
                          {t('Notifications')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={<Field as={Switch} name="marketingOptIn" checked={values.marketingOptIn} />}
                            label={t('Marketing_Promotions')}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Button variant="contained" onClick={() => setIsChangePasswordDialogOpen(true)}>
                              {t('Change_Password')}
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <LoadingButton
                              loading={isLoading}
                              type="submit"
                              variant="contained"
                              disabled={!dirty}
                              sx={styles.floatRight}
                            >
                              {t('Button_Save_Changes')}
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {process.env.REACT_APP_STAGE === 'staging' && (
                      <Grid item xs={6} sx={styles.mfaBtn}>
                        <Button variant="contained" onClick={() => setIsMFASettingsDialogOpen(true)}>
                          {t('Configure_MFA_Btn')}
                        </Button>
                      </Grid>
                    )}
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
        <Divider sx={{ marginY: 4 }} />
        <Box sx={styles.rolesContainer}>
          <Typography variant="h5" color="initial">
            {t('My_Roles')}
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="roles table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('Company')}</TableCell>
                  <TableCell>{t('Branch')}</TableCell>
                  <TableCell>{t('Role')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hasGlobalRole ? (
                  <TableRow key="global">
                    <TableCell>{t('Aiphone')}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{permissions?.global?.roleList[0]?.roleName}</TableCell>
                  </TableRow>
                ) : null}
                {!registeredUser ? generateBranchRoleRows() : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <SnackbarAlert
          type="error"
          time={6000}
          text={errorMessage}
          isOpen={isError}
          onClose={() => setIsError(false)}
        />
        <SnackbarAlert
          type="success"
          time={6000}
          text={successMessage}
          isOpen={isSuccess}
          onClose={() => {
            setIsSuccess(false);
          }}
        />
        <ChangePasswordDialog
          isChangePasswordDialogOpen={isChangePasswordDialogOpen}
          setIsChangePasswordDialogOpen={setIsChangePasswordDialogOpen}
          setErrorMessage={setErrorMessage}
          setIsError={setIsError}
          setSuccessMessage={setSuccessMessage}
          setIsSuccess={setIsSuccess}
        />
        <MFASettingsDialog dialogIsOpen={isMFASettingsDialogOpen} setDialogIsOpen={setIsMFASettingsDialogOpen} />
      </>
    );
  };

  // NOTE: Incase we want to add a feature to edit user profile, we can add a button to toggle the isEditing state and show the form fields for editing the user profile.
  return <ViewUser />;
};

const styles = {
  formContainer: {
    mt: 2
  },
  userContainer: {
    mt: 2
  },
  rolesContainer: {
    mt: 2
  },
  floatRight: {
    float: 'right'
  },
  mfaBtn: {
    marginTop: 2
  }
};

export default UserProfile;
